import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { ArrowLeft, ArrowRight, CaretDownFill } from 'react-bootstrap-icons';
import useLocalization from '../../hooks/useLocalization';
import Dropdown from './Dropdown';

const TabTitle = ({ title, isTabActive }) => {
  return <>{title}</>;
};

const Tabs = ({
  tabs = [],
  labelKey = 'name',
  valueKey = '_id',
  tabVariantColors = [],
  activeTab,
  selectedTab,
  onTabClick,
  onEditClick,
  onDeleteClick,
  onCloneClick,
  onToggleSandboxClick,
  onKillClick,
  onSelectClick,
  disabled,
  scrollable,
  readOnlyMode,
  activeTabVariant = 'primary'
}) => {
  const { translate, isRTL } = useLocalization();
  const tabsContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const tabsContainerStyle = useMemo(
    () =>
      scrollable
        ? {
            overflowX: 'auto',
            whiteSpace: 'nowrap'
          }
        : {},
    [scrollable]
  );

  useEffect(() => {
    const THRESHOLD = 10;

    const container = tabsContainerRef.current;

    function updateScrollArrows() {
      const scrollLeftMax = container.scrollWidth - container.clientWidth;

      if (isRTL) {
        //for rtl scrollLeft is negative
        setCanScrollRight(-container.scrollLeft > THRESHOLD);
        setCanScrollLeft(-container.scrollLeft < scrollLeftMax - THRESHOLD);
      } else {
        setCanScrollLeft(container.scrollLeft > THRESHOLD);
        setCanScrollRight(container.scrollLeft < scrollLeftMax - THRESHOLD);
      }
    }

    updateScrollArrows();
    container.addEventListener('scroll', updateScrollArrows);

    return () => container.removeEventListener('scroll', updateScrollArrows);
  }, []);

  const handleScrollClick = direction => {
    const container = tabsContainerRef.current;
    if (isRTL) {
      if (direction === 'left') {
        container.scroll({ left: container.scrollLeft - container.clientWidth / 2, behavior: 'smooth' });
      } else {
        container.scroll({ left: container.scrollLeft + container.clientWidth / 2, behavior: 'smooth' });
      }
    } else {
      if (direction === 'left') {
        container.scroll({ left: container.scrollLeft - container.clientWidth / 2, behavior: 'smooth' });
      } else {
        container.scroll({ left: container.scrollLeft + container.clientWidth / 2, behavior: 'smooth' });
      }
    }
  };

  const hasActions = useMemo(
    () =>
      (onEditClick || onDeleteClick || onCloneClick || onKillClick || onSelectClick || onToggleSandboxClick) &&
      !readOnlyMode,
    [onEditClick, onDeleteClick, onCloneClick, onKillClick, onSelectClick, onToggleSandboxClick, readOnlyMode]
  );

  return (
    <div style={{ position: 'relative' }}>
      {scrollable && canScrollLeft && (
        <Button
          size="sm"
          variant="light"
          className="rounded-start h-100  border"
          style={{
            position: 'absolute',
            top: '50%',
            left: -2,
            transform: 'translateY(-50%)',
            zIndex: 1
          }}
          onClick={() => handleScrollClick('left')}
        >
          <ArrowLeft />
        </Button>
      )}
      <div ref={tabsContainerRef} style={tabsContainerStyle}>
        {tabs.map((tab, index) => {
          const isObject = typeof tab === 'object';
          const label = isObject ? tab[labelKey] : tab;
          const value = isObject ? tab[valueKey] : tab;

          return (
            <div key={value} className="d-inline-block me-1">
              <>
                {hasActions && activeTab === value ? (
                  <Dropdown
                    trigger={
                      <Button
                        style={{ ...buttonStyle, ...(selectedTab === value ? { border: '1px solid #e60707' } : {}) }}
                        onClick={() => onTabClick && onTabClick(value)}
                        size={'sm'}
                        disabled={disabled}
                        variant={activeTab === value ? activeTabVariant : tabVariantColors[index] || 'dark'}
                      >
                        <TabTitle title={label} isTabActive={activeTab === value} />
                        <CaretDownFill size={10} className="mx-1" />
                      </Button>
                    }
                    className="d-inline-block mx-1"
                  >
                    <ListGroup>
                      {[
                        { onClick: onEditClick, text: 'edit' },
                        { onClick: onDeleteClick, text: 'delete' },
                        { onClick: onCloneClick, text: 'clone' },
                        { onClick: onToggleSandboxClick, text: 'clone' },
                        { onClick: onKillClick, text: 'archive' },
                        { onClick: onSelectClick, text: selectedTab === value ? 'unselect' : 'select' }
                      ]
                        .filter(a => a.onClick)
                        .map(({ onClick, text }) => (
                          <ListGroupItem className="px-3 py-2 large" action onClick={() => onClick(value)}>
                            {translate(text)}
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </Dropdown>
                ) : (
                  <Button
                    style={{ ...buttonStyle, ...(selectedTab === value ? { border: '3px solid #e60707' } : {}) }}
                    onClick={() => onTabClick && onTabClick(value)}
                    size={'sm'}
                    disabled={disabled}
                    variant={activeTab === value ? activeTabVariant : tabVariantColors[index] || 'dark'}
                  >
                    <TabTitle title={label} /* isSelected={selectedTab === t} */ isTabActive={activeTab === value} />
                  </Button>
                )}
              </>
            </div>
          );
        })}
      </div>
      {scrollable && canScrollRight && (
        <Button
          size="sm"
          variant="light"
          className="rounded-end h-100  border"
          style={{
            position: 'absolute',
            top: '50%',
            right: -2,
            transform: 'translateY(-50%)',
            zIndex: 1
          }}
          onClick={() => handleScrollClick('right')}
        >
          <ArrowRight />
        </Button>
      )}
    </div>
  );
};

const buttonStyle = { fontSize: 13, borderWidth: '1px' };

export default Tabs;
