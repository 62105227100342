import { uniqueId } from 'lodash';
import React, { useMemo } from 'react';
import { Button, FormSelect, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ArrowDownShort, InfoCircleFill, Lightbulb, PlusCircle, Stars, Trash } from 'react-bootstrap-icons';
import { formatCurrency, formatPercentage } from '../../components/admin/manage-users/customer/strategies/helper';
import CurrencyInput from '../../components/common/CurrencyInput';
import PercentageInput from '../../components/common/PercentageInput';
import UnderlineButton from '../../components/common/UnderlineButton';
import AppTable from '../../components/common/app-table/AppTable';
import { monthSmall } from '../../helpers/constants';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel } from '../../helpers/global';
import useAuth from '../../hooks/useAuth';
import useLocalization from '../../hooks/useLocalization';

const getPaymentHeaders = ({ translate, strategyYears }) => [
  {
    name: translate('month'),
    key: 'month',
    type: 'dropdown',
    options: monthSmall.map((m, i) => ({ option: m, value: i }))
  },
  {
    name: translate('year'),
    key: 'year',
    type: 'dropdown',
    options: strategyYears.map(y => ({ option: y, value: y }))
  },
  {
    name: translate('value'),
    type: 'number',
    format: 'currency',
    key: 'amount'
  },
  {
    name: '%',
    type: 'number',
    format: '%',
    key: '%'
  }
];

const PaymentRow = ({
  disabled,
  disableDates,
  payment,
  paymentHeaders,
  onPaymentDeleteClick,
  onChange,
  PrefixElement
}) => {
  const { user } = useAuth();

  return (
    <tr key={payment.id}>
      <td
        className={`p-1 px-2 text-center align-middle ${
          payment.isSystemGenerated ? 'bg-primary-light bg-opacity-25' : ''
        }`}
      >
        {PrefixElement}
      </td>
      {paymentHeaders.map(({ name, type, options, key, format }) => (
        <td className="bg-white p-0" key={name}>
          {type === 'dropdown' && (
            <FormSelect
              className="mb-0 border-0"
              autoComplete="off"
              size="sm"
              disabled={disabled || (['month', 'year'].includes(key) && disableDates)}
              value={payment[key]}
              onChange={e => {
                onChange(payment, key, type, format, e.target.value);
              }}
            >
              {options.map(o => (
                <option key={o.value} value={o.value}>
                  {o.option}
                </option>
              ))}
            </FormSelect>
          )}
          {type === 'number' &&
            (format === 'currency' ? (
              <CurrencyInput
                className="mb-0 border-0"
                autoComplete="off"
                size="sm"
                disabled={disabled || (['month', 'year'].includes(key) && disableDates)}
                onChange={value => onChange(payment, key, type, format, value)}
                value={payment[key]}
              />
            ) : (
              <PercentageInput
                className="mb-0 border-0"
                autoComplete="off"
                size="sm"
                disabled={disabled}
                onChange={value => onChange(payment, key, type, format, value)}
                value={payment[key]}
              />
            ))}
        </td>
      ))}
      {!disabled && !findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
        <td className="p-0 align-middle">
          <div className="text-center">
            {onPaymentDeleteClick && (
              <Button variant="outline-danger" className="px-1 py-0 " onClick={() => onPaymentDeleteClick(payment)}>
                <Trash size={9} />
              </Button>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

const PaperApartmentPayments = ({
  apartmentValue = 0,
  paperApartmentPayments = [],
  onPaymentsChange,
  strategyYears = [],
  onAutofillRemainingPaymentClick,
  downPaymentPercentage = 0,
  downPaymentAmount = 0,
  onDownPaymentAmountChange,
  onDownPaymentPercentageChange,
  apartmentBuyMonth,
  apartmentBuyYear,
  disabled
}) => {
  const { langCode, translate } = useLocalization();
  const { user } = useAuth();

  const paymentHeaders = useMemo(() => getPaymentHeaders({ translate, strategyYears }), [langCode, strategyYears]);

  const totalPaymentPercentage = useMemo(() => {
    const paymentsPercentage = paperApartmentPayments.reduce((acc, p) => {
      const numPercentage = Number(p['%']);
      return acc + (isNaN(numPercentage) ? 0 : numPercentage);
    }, 0);

    return paymentsPercentage + (isNaN(downPaymentPercentage) ? 0 : downPaymentPercentage);
  }, [paperApartmentPayments, downPaymentPercentage]);

  const onChange = (payment, field, type, format, value) => {
    payment[field] = value;
    payment.isSystemGenerated = false;

    if (type === 'number') {
      if (field === '%') {
        //update number also
        payment.amount = apartmentValue * value * 0.01;
      } else if (field === 'amount') {
        //update % also
        payment['%'] = apartmentValue === 0 ? 0 : ((value * 100) / apartmentValue).toFixed(2);
      }
    }

    onPaymentsChange && onPaymentsChange(paperApartmentPayments.map(p => (p.id === payment.id ? { ...payment } : p)));
  };

  const needsSortingByDate = useMemo(() => {
    let isSorted = true;
    for (let i = 1; i < paperApartmentPayments.length; i++) {
      const prevPayment = paperApartmentPayments[i - 1];
      const currentPayment = paperApartmentPayments[i];
      if (
        prevPayment.year > currentPayment.year ||
        (prevPayment.year === currentPayment.year && prevPayment.month > currentPayment.month)
      ) {
        isSorted = false;
        break;
      }
    }
    return !isSorted;
  }, [paperApartmentPayments]);

  const renderDownPaymentTooltip = props => (
    <Tooltip color="dark" {...props}>
      {translate('down_payment')}
    </Tooltip>
  );

  const onPaymentAddClick = () => {
    const newPayment = {
      id: uniqueId(),
      amount: 0,
      '%': 0,
      month: 0,
      year: strategyYears[0]
    };

    const payments = paperApartmentPayments || [];
    const firstAutoPaymentIndex = payments.findIndex(payment => payment.isSystemGenerated);
    const insertIndex = firstAutoPaymentIndex === -1 ? payments.length : firstAutoPaymentIndex;
    const updatedPayments = [...payments.slice(0, insertIndex), newPayment, ...payments.slice(insertIndex)];
    onPaymentsChange(updatedPayments);
  };

  const onPaymentDeleteClick = payment => {
    onPaymentsChange(
      paperApartmentPayments.filter(p => p.id !== payment.id),
      false
    );
  };

  const onSortPaymentsClick = () => {
    const payments = paperApartmentPayments;
    const sortedPayments = payments.sort((a, b) => a.year - b.year || a.month - b.month);
    onPaymentsChange([...sortedPayments], false);
  };

  return (
    <>
      <AppTable className="mb-0">
        <thead>
          <tr className="text-white bg-dark">
            <th></th>

            {paymentHeaders.map(({ name, key }) => (
              <th style={{ minWidth: 100 }} key={name}>
                <div className="d-flex align-items-center justify-content-between">
                  {name}{' '}
                  {!disabled && needsSortingByDate && key === 'year' && (
                    <ArrowDownShort size={18} className="hover" onClick={onSortPaymentsClick} />
                  )}
                </div>
              </th>
            ))}
            {!disabled && !findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
              <th style={{ minWidth: 50 }}>{translate('actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {/* DOWNPAYMENT */}

          <PaymentRow
            disableDates
            disabled={disabled}
            payment={{
              id: 'downPayment',
              month: apartmentBuyMonth,
              year: apartmentBuyYear,
              amount: downPaymentAmount,
              '%': downPaymentPercentage
            }}
            paymentHeaders={paymentHeaders}
            onChange={(payment, key, type, format, value) => {
              if (key === 'amount') {
                onDownPaymentAmountChange && onDownPaymentAmountChange(value);
              } else if (key === '%') {
                onDownPaymentPercentageChange && onDownPaymentPercentageChange(value);
              }
            }}
            PrefixElement={
              <div className="d-flex align-items-center">
                <OverlayTrigger placement="bottom" overlay={renderDownPaymentTooltip}>
                  <h6 className="mb-0 large fw-bold px-1">
                    <InfoCircleFill size={14} />
                  </h6>
                </OverlayTrigger>
              </div>
            }
          />

          {paperApartmentPayments.map(payment => (
            <PaymentRow
              disabled={disabled}
              key={payment.id}
              payment={payment}
              paymentHeaders={paymentHeaders}
              onPaymentDeleteClick={onPaymentDeleteClick}
              onChange={onChange}
              PrefixElement={payment.isSystemGenerated ? <Lightbulb className="text-primary" size={15} /> : null}
            />
          ))}
          <tr className="bg-primary-light">
            <td colSpan={paymentHeaders.length}>
              <h6 className="text-end mb-0 large">{translate('total')}:</h6>
            </td>
            <td>
              <h6 className="mb-0 large fw-bold">{formatPercentage(totalPaymentPercentage, true, true)}</h6>
            </td>
            {!disabled && (
              <td>
                {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
                  <UnderlineButton
                    fontSize="smallFont"
                    variant="success"
                    Icon={PlusCircle}
                    text={translate('new')}
                    onClick={onPaymentAddClick}
                  />
                )}
              </td>
            )}
          </tr>
        </tbody>
      </AppTable>
      <div className="d-flex">
        {!disabled && (
          <div className="d-flex flex-grow-1 mt-2 align-items-center">
            {totalPaymentPercentage < 100 && (
              <UnderlineButton
                fontSize="smallFont"
                variant="primary"
                Icon={Stars}
                text={translate('autofill_remaining_payment', {
                  remainingPayment: formatPercentage(100 - totalPaymentPercentage)
                })}
                onClick={onAutofillRemainingPaymentClick}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PaperApartmentPayments;
