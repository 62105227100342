import React, { useState } from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import FormGenerator from '../../../../../form-generator/FormGenerator';
import HorizontalProgress from '../../../../common/HorizontalProgress';
import { addLiabilityForm, updateLiabilityForm } from './form';
import ExplainatoryVideoButton from '../../../../common/ExplainatoryVideoButton';
import LeverageOptionsModal from './LeverageOptionsModal';
import UnderlineButton from '../../../../common/UnderlineButton';
import { InfoCircle } from 'react-bootstrap-icons';

const LiabilityModal = ({
  show,
  toBeEditedLiability,
  onHide,
  showProgress,
  strategyYears,
  linkedAsset,
  fromAssetCreateForm,
  assetOptions = [],
  cashAssetOptions = [],
  loanType,
  loanStartDateAutoCalculated,
  loanEndDateAutoCalculated,
  onLiabilitySubmit,
  onGroupLiabilitySubmit,
  liabilityStartYear,
  liabilityEndYear,
  incompleteYearsOfStrategy,
  loanEditMode,
  compTable,
  strategy,
  calculatedStrategy,
  onLeverageOptionSelect
}) => {
  const [showLeverageOptions, setShowLeverageOptions] = useState(false);

  const onViewLeverageOptionClick = () => {
    setShowLeverageOptions(true);
  };

  const onLeverageOptionSelectClick = (option, startMonth, startYear) => {
    onLeverageOptionSelect(option, startMonth, startYear);
    setShowLeverageOptions(false);
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton={!showProgress}>
          <Modal.Title className="flex-grow-1">
            <h6 className="mb-0">{toBeEditedLiability ? 'Update Liability' : 'Add New Liability'}</h6>
          </Modal.Title>
          <UnderlineButton
            className="mx-2"
            iconSize={15}
            variant="dark"
            Icon={InfoCircle}
            fontSize="large"
            text={'View Leverage Options'}
            onClick={onViewLeverageOptionClick}
          />
        </Modal.Header>
        <Modal.Body className="overflow-auto">
          <div className="px-3">
            <ExplainatoryVideoButton videoKey="add_liability" />
          </div>
          {/* From asset create form then do not allow selecting other assets */}
          {fromAssetCreateForm && linkedAsset && (
            <div className="mt-3 px-2 text-dark">
              <h6 className="large">
                Linked Asset:{' '}
                <Badge bg="dark">
                  {linkedAsset.name} ( {linkedAsset.value} )
                </Badge>
              </h6>
              <hr className="my-1" />
            </div>
          )}
          {show && (
            <FormGenerator
              prefix="liability"
              formJson={
                toBeEditedLiability
                  ? updateLiabilityForm(
                      strategyYears,
                      liabilityStartYear,
                      liabilityEndYear,
                      incompleteYearsOfStrategy,
                      linkedAsset,
                      fromAssetCreateForm,
                      assetOptions,
                      cashAssetOptions,
                      loanType,
                      loanStartDateAutoCalculated,
                      loanEndDateAutoCalculated,
                      loanEditMode
                    )
                  : addLiabilityForm(
                      strategyYears,
                      liabilityStartYear,
                      liabilityEndYear,
                      incompleteYearsOfStrategy,
                      linkedAsset,
                      fromAssetCreateForm,
                      assetOptions,
                      cashAssetOptions,
                      loanType,
                      loanStartDateAutoCalculated,
                      loanEndDateAutoCalculated,
                      loanEditMode
                    )
              }
              formValues={
                toBeEditedLiability ? { 'Update Liability': { ...toBeEditedLiability } } : { 'New Liability': {} }
              }
            />
          )}
          {showProgress && (
            <HorizontalProgress text={toBeEditedLiability ? 'Updating Liability...' : 'Adding Liability....'} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={showProgress}
            size="sm"
            variant="success"
            className="mr-2 text-white"
            onClick={onLiabilitySubmit}
          >
            {toBeEditedLiability ? 'Update' : 'Add'} Liability
          </Button>

          <Button
            disabled={showProgress}
            size="sm"
            variant="success"
            className="mr-2 text-white"
            onClick={onGroupLiabilitySubmit}
          >
            Group {toBeEditedLiability ? 'Update' : 'Add'} Liability
          </Button>
        </Modal.Footer>
      </Modal>
      {show && (
        <LeverageOptionsModal
          show={showLeverageOptions}
          onHide={() => setShowLeverageOptions(false)}
          strategyYears={strategyYears}
          incompleteYearsOfStrategy={incompleteYearsOfStrategy}
          assetOptions={assetOptions}
          compTable={compTable}
          calculatedStrategy={calculatedStrategy}
          onOptionSelect={onLeverageOptionSelectClick}
        />
      )}
    </>
  );
};

export default LiabilityModal;
