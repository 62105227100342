import React from 'react';
import { Modal } from 'react-bootstrap';

function FullscreenModal({ show, onHide, title, fullscreen, children }) {
  return (
    <Modal size="xl" show={true} onHide={onHide} fullscreen={fullscreen ?? true} backdrop="static">
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="p-0">{children}</Modal.Body>
    </Modal>
  );
}

export default FullscreenModal;
