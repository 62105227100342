import React, { useMemo, useState } from 'react';
import Switch from 'react-switch';
import useLocalization from '../../hooks/useLocalization';

const SwitchInput = ({
  value,
  onChange,
  falseValue = false,
  trueValue = true,
  label,
  threeState = false,
  neutralValue = null,
  className = ''
}) => {
  const [dir, setDir] = useState('right');
  const { isRTL } = useLocalization();

  const twoStateInternalValue = useMemo(() => {
    return value === trueValue;
  }, [value, trueValue]);

  const threeStateInternalValue = useMemo(() => {
    if (value === undefined || value === null || value === neutralValue) return 0; // Neutral state
    return value === trueValue ? 1 : value === falseValue ? -1 : 0; // 1 for true, -1 for false
  }, [value, trueValue, neutralValue]);

  const internalValue = useMemo(() => {
    return threeState ? threeStateInternalValue : twoStateInternalValue;
  }, [threeState, threeStateInternalValue, twoStateInternalValue]);

  const handleToggle = () => {
    let nextState;

    if (internalValue === 0 && dir === 'right') {
      nextState = 1;
      setDir('mean');
    } else if (internalValue === 1 && dir === 'mean') {
      nextState = 0;
      setDir('left');
    } else if (internalValue === 0 && dir === 'left') {
      nextState = -1;
      setDir('mean');
    } else if (internalValue === -1 && dir === 'mean') {
      nextState = 0;
      setDir('right');
    }

    onChange(nextState === 1 ? trueValue : nextState === -1 ? falseValue : neutralValue);
  };

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {label && <span className="mx-2 mid">{label}</span>}
      {threeState ? (
        <div>
          <div
            onClick={handleToggle}
            style={{
              cursor: 'pointer',
              backgroundColor:
                threeStateInternalValue === 1 ? '#4caf50' : threeStateInternalValue === -1 ? '#ff0000' : '#e0e0e0',
              width: '50px',
              height: '20px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent:
                threeStateInternalValue === 1 ? 'flex-end' : threeStateInternalValue === -1 ? 'flex-start' : 'center',
              alignItems: 'center',
              padding: '1px',
              transition: 'background-color 0.3s ease',
              position: 'relative'
            }}
          >
            {threeStateInternalValue === 1 && (
              <span
                style={{
                  color: '#fff',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  ...(isRTL ? { right: '8px' } : { left: '8px' }),
                  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
                }}
              >
                ✓
              </span>
            )}
            {threeStateInternalValue === -1 && (
              <span
                style={{
                  color: '#fff',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  ...(isRTL ? { left: '8px' } : { right: '8px' }),
                  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
                }}
              >
                ✗
              </span>
            )}
            <div
              style={{
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                zIndex: 1
              }}
            />
          </div>
        </div>
      ) : (
        <Switch
          checked={internalValue}
          onChange={checked => onChange(checked ? trueValue : falseValue)}
          height={17}
          width={50}
          onColor="#4caf50"
          offColor="#ff0000"
        />
      )}
    </div>
  );
};

export default SwitchInput;
