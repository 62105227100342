export const getRsuCalculations = ({
  postponingTaxMarginalRateOfIncome,
  postponingTaxCapitalAppreciationTax,
  payingTaxMarginalRateOfIncome,
  payingTaxCapitalAppreciationTax,
  postponingTaxValueWithMarginalTaxRate,
  postponingTaxValueWithCapitalAppreciationTaxRate,
  payingTaxValueWithMarginalTaxRate,
  payingTaxValueWithCapitalAppreciationTaxRate,
  postponingTaxYearlyAppreciation,
  payingTaxYearlyAppreciation,
  postponingTaxHoldingPeriodInYears,
  payingTaxHoldingPeriodInYears,

  postponingTaxPayingTaxAtPeriodEnd,
  payingTaxPayingTaxAtPeriodEnd
}) => {
  //postpone
  const postponingTaxTotalValue =
    postponingTaxValueWithMarginalTaxRate + postponingTaxValueWithCapitalAppreciationTaxRate;
  const postponingTaxFutureValuePreTax =
    postponingTaxTotalValue * Math.pow(1 + postponingTaxYearlyAppreciation, postponingTaxHoldingPeriodInYears);
  const postponingTaxTax =
    postponingTaxValueWithMarginalTaxRate * postponingTaxMarginalRateOfIncome +
    (postponingTaxFutureValuePreTax - postponingTaxValueWithMarginalTaxRate) * postponingTaxCapitalAppreciationTax;

  //paying
  const payingTaxTotalValue =
    payingTaxValueWithMarginalTaxRate * (1 - payingTaxMarginalRateOfIncome) +
    payingTaxValueWithCapitalAppreciationTaxRate * (1 - payingTaxCapitalAppreciationTax);
  const payingTaxFutureValuePreTax =
    payingTaxTotalValue * Math.pow(1 + payingTaxYearlyAppreciation, payingTaxHoldingPeriodInYears);
  const payingTaxTax = (payingTaxFutureValuePreTax - payingTaxTotalValue) * payingTaxCapitalAppreciationTax;

  return {
    postponingTaxTotalValue,
    postponingTaxFutureValuePreTax,
    postponingTaxTax,
    postponingTaxNetValue: postponingTaxFutureValuePreTax - (postponingTaxPayingTaxAtPeriodEnd ? postponingTaxTax : 0),

    payingTaxTotalValue,
    payingTaxFutureValuePreTax,
    payingTaxTax,
    payingTaxNetValue: payingTaxFutureValuePreTax - (payingTaxPayingTaxAtPeriodEnd ? payingTaxTax : 0)
  };
};

export const getOptionsCalculations = ({
  afterMarginalRateOfIncome,
  afterCapitalAppreciationTax,
  afterOptionsStrikePrice,
  afterStockValueAtOptionsReceivalDate,
  afterCurrentStockPrice,
  afterStockAmount,
  afterCompanyType,

  preMarginalRateOfIncome,
  preCapitalAppreciationTax,
  preOptionsStrikePrice,
  preStockValueAtOptionsReceivalDate,
  preCurrentStockPrice,
  preStockAmount,
  preCompanyType
}) => {
  const isAfterPrivateCompany = afterCompanyType === 'privateCompany';
  const isPrePrivateCompany = preCompanyType === 'privateCompany';

  const afterRequiredEquityForExercising = afterOptionsStrikePrice * afterStockAmount;
  const afterProfitAtExcersisingPreTax = afterCurrentStockPrice * afterStockAmount - afterRequiredEquityForExercising;

  const marginalTaxToUse = isAfterPrivateCompany ? afterCapitalAppreciationTax : afterMarginalRateOfIncome;
  const afterTaxOnExercising =
    (afterStockValueAtOptionsReceivalDate - afterOptionsStrikePrice) * marginalTaxToUse * afterStockAmount +
    (afterCurrentStockPrice - afterStockValueAtOptionsReceivalDate) * afterCapitalAppreciationTax * afterStockAmount;

  const preRequiredEquityForExercising = preOptionsStrikePrice * preStockAmount;
  const preProfitAtExcersisingPreTax = preCurrentStockPrice * preStockAmount - preRequiredEquityForExercising;

  const preTaxOnExercising =
    (preStockValueAtOptionsReceivalDate - preOptionsStrikePrice) * preMarginalRateOfIncome * preStockAmount +
    (preCurrentStockPrice - preStockValueAtOptionsReceivalDate) * preMarginalRateOfIncome * preStockAmount;

  return {
    afterRequiredEquityForExercising,
    afterProfitAtExcersisingPreTax,
    afterTaxOnExercising,
    afterNetAfterExercisingAndSelling: afterProfitAtExcersisingPreTax - afterTaxOnExercising,

    preRequiredEquityForExercising,
    preProfitAtExcersisingPreTax,
    preTaxOnExercising,
    preNetAfterExercisingAndSelling: preProfitAtExcersisingPreTax - preTaxOnExercising
  };
};
