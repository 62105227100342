import React, { useMemo } from 'react';
import { Button, FormSelect } from 'react-bootstrap';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import CurrencyInput from '../../components/common/CurrencyInput';
import PercentageInput from '../../components/common/PercentageInput';
import SwitchInput from '../../components/common/SwitchInput';
import UnderlineButton from '../../components/common/UnderlineButton';
import AppTable from '../../components/common/app-table/AppTable';
import { monthSmall } from '../../helpers/constants';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel } from '../../helpers/global';
import useAuth from '../../hooks/useAuth';
import useLocalization from '../../hooks/useLocalization';

const getPartialSalesHeaders = (translate, strategyYears, isRSU) => [
  {
    name: translate('after_tax'),
    key: 'afterTax',
    type: 'switch'
  },
  {
    name: translate('value'),
    key: 'value',
    type: 'number',
    format: 'currency'
  },
  {
    name: translate('month'),
    key: 'month',
    type: 'dropdown',
    options: monthSmall.map((m, i) => ({ option: m, value: i }))
  },
  {
    name: translate('year'),
    key: 'year',
    type: 'dropdown',
    options: strategyYears.map(y => ({ option: y, value: y }))
  },
  {
    name: translate(isRSU ? 'marginal_tax' : 'tax'),
    type: 'number',
    format: '%',
    key: 'taxPercentage'
  }
];

const PartialSales = ({
  sales = [],
  onSalesChange,
  onSalesAddClick,
  onSalesDeleteClick,
  strategyYears = [],
  isRSU
}) => {
  const { langCode, translate } = useLocalization();
  const partialSalesHeaders = useMemo(() => getPartialSalesHeaders(translate, strategyYears, isRSU), [
    langCode,
    strategyYears,
    isRSU
  ]);
  const { user } = useAuth();

  const onChange = (partialSale, field, type, format, value) => {
    partialSale[field] = value;

    onSalesChange && onSalesChange(sales.map(p => (p.id === partialSale.id ? { ...partialSale } : p)));
  };

  return (
    <>
      <AppTable className="mb-0 w-100">
        <thead>
          <tr className="text-white bg-dark">
            {partialSalesHeaders.map(({ name }) => (
              <th style={{ minWidth: 100 }} key={name}>
                {name}
              </th>
            ))}
            {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
              <th style={{ minWidth: 70 }}>{translate('actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {sales.length > 0 ? (
            <>
              {sales.map(payment => (
                <tr key={payment.id}>
                  {partialSalesHeaders.map(({ name, type, options, key, format }) => (
                    <td valign="middle" align="center" className="bg-white p-0" key={name}>
                      {type === 'dropdown' && (
                        <FormSelect
                          className="mb-0 border-0"
                          autoComplete="off"
                          size="sm"
                          value={payment[key]}
                          onChange={e => {
                            onChange(payment, key, type, format, e.target.value);
                          }}
                        >
                          {options.map(o => (
                            <option key={o.value} value={o.value}>
                              {o.option}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      {type === 'number' &&
                        (format === 'currency' ? (
                          <CurrencyInput
                            className="mb-0 border-0"
                            autoComplete="off"
                            size="sm"
                            onChange={value => onChange(payment, key, type, format, value)}
                            value={payment[key]}
                          />
                        ) : (
                          <PercentageInput
                            className="mb-0 border-0"
                            autoComplete="off"
                            size="sm"
                            onChange={value => onChange(payment, key, type, format, value)}
                            value={payment[key]}
                          />
                        ))}
                      {type === 'switch' && (
                        <SwitchInput
                          onChange={value => onChange(payment, key, type, format, value)}
                          value={payment[key]}
                        />
                      )}
                    </td>
                  ))}
                  {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
                    <td className="p-0">
                      <div className="text-center">
                        <Button
                          variant="outline-danger"
                          className="px-1 py-0 "
                          onClick={() => onSalesDeleteClick(payment)}
                        >
                          <Trash size={9} />
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={partialSalesHeaders.length + 1}>
                <h6 className="text-muted text-center mb-0 smallFont">{translate('nothing_to_show')}</h6>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
      <div className="text-end">
        {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
          <UnderlineButton
            fontSize="smallFont"
            variant="success"
            Icon={PlusCircle}
            text={translate('new')}
            onClick={onSalesAddClick}
          />
        )}
      </div>
    </>
  );
};

export default PartialSales;
