import { FormCheck, FormSelect } from 'react-bootstrap';
import PercentageInput from '../../form-generator/components/PercentageInput';
import PaperApartmentPayments from './PaperApartmentPayments';
import {
  formatCurrency,
  formatPercentage,
  getNumberFromFormattedPercentage,
  getNumberFromFormattedValue
} from '../admin/manage-users/customer/strategies/helper';
import AutoGrowTextArea from '../common/AutoGrowTextArea';
import CompactBlockSelectInput from '../common/CompactBlockSelectInput';
import CurrencyInput from '../common/CurrencyInput';
import MonthYearInput from '../common/MonthYearInput';
import RangeInput from '../common/RangeInput';
import SwitchInput from '../common/SwitchInput';
import { getPercentageValue, getRemainingSuggestedPaperApartmentPayments } from './helpers';
import Summary from './IrrBreakdownSummary';
import { APARTMENT_TYPES } from '../../helpers/constants';

const DataRow = ({
  label,
  objectKey,
  extraInfo = null,
  isInput,
  percentageOfField,
  type,
  min,
  max,
  trueValue = 'Yes',
  falseValue = 'No',
  blockMaxWidth = null,
  appartments = [],
  onValueChange,
  allFields,
  isRtlLanguage,
  collapsedApartments,
  tabIndex,
  totalFieldsCount,
  options = [],
  readOnlyMode,
  dataListOptions,
  as,
  required = false,
  apartmentType
}) => {
  const calculatePaperApartmentPayments = (app, addToExistingPayments = false) => {
    let newValues = { ...app };
    //reset paper apartment payments
    newValues.paperApartmentPayments = !addToExistingPayments
      ? []
      : newValues.paperApartmentPayments?.filter(p => !p.isSystemGenerated) || [];

    let newPayments = [];
    if (apartmentType === APARTMENT_TYPES.PAPER) {
      newPayments = [...newValues.paperApartmentPayments, ...getRemainingSuggestedPaperApartmentPayments(newValues)];
    }

    onValueChange(app.id, 'paperApartmentPayments', 'paperApartmentPayments', newPayments);
  };

  const onAutofillRemainingPaymentClick = app => {
    calculatePaperApartmentPayments(app, true);
  };

  const onDownPaymentPercentageChange = (app, value) => {
    const apartmentValue = getNumberFromFormattedValue(app.inputPrice) || 0;
    calculatePaperApartmentPayments({ ...app, downPayment: value }, true);

    onValueChangeInternal(app, 'downPayment', 'number', value);
    onValueChangeInternal(app, 'downPaymentAmount', 'number', value * 0.01 * apartmentValue);
  };

  const onDownPaymentAmountChange = (app, value) => {
    const apartmentValue = getNumberFromFormattedValue(app.inputPrice) || 0;
    const downPaymentPercentage = apartmentValue ? ((value || 0) * 100) / apartmentValue : 0;

    calculatePaperApartmentPayments({ ...app, downPayment: downPaymentPercentage }, true);

    onValueChangeInternal(app, 'downPayment', 'number', downPaymentPercentage);
    onValueChangeInternal(app, 'downPaymentAmount', 'number', value);
  };

  const onValueChangeInternal = (app, objectKey, type, value, additionalInfo) => {
    if (
      ['inputPrice', 'estimatedTimeForFinishingContruction', 'remainingPayment', 'paperApartmentPayments'].includes(
        objectKey
      )
    ) {
      if (objectKey === 'paperApartmentPayments' && additionalInfo?.recalculatePayments) {
        calculatePaperApartmentPayments({ ...app, [objectKey]: value }, true);
        return;
      }

      calculatePaperApartmentPayments({ ...app, [objectKey]: value });
    }
    onValueChange(app.id, objectKey, type, value, additionalInfo);
  };

  return (
    <tr>
      <td className={`p-1 bg-white text-${isRtlLanguage ? 'end' : 'start'} calc-column`}>
        <b>
          {label}
          {type === 'percentage' && ' (%)'}
        </b>
      </td>
      {appartments?.map((app, appIndex) => (
        <td
          key={app.id}
          className={`p-1 text-${isRtlLanguage ? 'end' : 'start'}  ${
            !collapsedApartments.includes(app.id) ? 'calc-column' : ''
          }`}
        >
          {!collapsedApartments.includes(app.id) && (
            <div className="d-flex align-items-center">
              {isInput ? (
                type === 'block-select' ? (
                  <CompactBlockSelectInput
                    isRtlLanguage={isRtlLanguage}
                    value={app[objectKey]}
                    onChange={value => onValueChangeInternal(app, objectKey, type, value)}
                    options={options}
                    blockMaxWidth={blockMaxWidth}
                  />
                ) : type === 'switch' ? (
                  <SwitchInput
                    value={app[objectKey]}
                    onChange={value => onValueChangeInternal(app, objectKey, type, value)}
                    trueValue={trueValue}
                    falseValue={falseValue}
                    threeState={true}
                  />
                ) : type === 'range' ? (
                  <RangeInput
                    isRtlLanguage={isRtlLanguage}
                    value={app[objectKey]}
                    onChange={value => onValueChangeInternal(app, objectKey, type, value)}
                    min={min}
                    max={max}
                  />
                ) : type === 'monthYearInput' ? (
                  <MonthYearInput
                    isRtlLanguage={isRtlLanguage}
                    value={app[objectKey]}
                    onChange={value => onValueChangeInternal(app, objectKey, type, value)}
                  />
                ) : type === 'boolean' ? (
                  <FormCheck
                    tabIndex={appIndex * totalFieldsCount + tabIndex}
                    size="sm"
                    disabled={readOnlyMode}
                    checked={app[objectKey]}
                    onChange={e => onValueChangeInternal(app, objectKey, type, e.target.checked)}
                  />
                ) : type === 'rate' || type === 'dropdown' ? (
                  <FormSelect
                    tabIndex={appIndex * totalFieldsCount + tabIndex}
                    size="sm"
                    disabled={readOnlyMode}
                    value={app[objectKey]}
                    onChange={e =>
                      onValueChangeInternal(
                        app,
                        objectKey,
                        type,
                        e.target.value === 'select_one' ? null : e.target.value
                      )
                    }
                  >
                    {!required && <option value={'select_one'}>Select one</option>}
                    {type === 'rate'
                      ? [
                          'Select...',
                          ...Array(10)
                            .fill(0)
                            .map((a, i) => i + 1)
                        ].map(o => (
                          <option key={o} value={o === 'Select...' ? '' : o}>
                            {o}
                          </option>
                        ))
                      : options.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                  </FormSelect>
                ) : type === 'percentage' ? (
                  <PercentageInput
                    tabIndex={appIndex * totalFieldsCount + tabIndex}
                    className="m-0"
                    size="sm"
                    disabled={readOnlyMode}
                    value={app[objectKey]}
                    onChange={e => onValueChangeInternal(app, objectKey, type, e.target.value)}
                  />
                ) : type === 'paperApartmentPayments' ? (
                  <PaperApartmentPayments
                    tabIndex={appIndex * totalFieldsCount + tabIndex}
                    disabled={readOnlyMode}
                    paperApartmentPayments={app[objectKey]}
                    apartmentBuyMonth={0}
                    apartmentValue={getNumberFromFormattedValue(app.inputPrice) || 0}
                    downPaymentPercentage={getNumberFromFormattedPercentage(app.downPayment) || 0}
                    downPaymentAmount={app.downPaymentAmount}
                    onAutofillRemainingPaymentClick={() => onAutofillRemainingPaymentClick(app)}
                    onDownPaymentPercentageChange={value => onDownPaymentPercentageChange(app, value)}
                    onDownPaymentAmountChange={value => onDownPaymentAmountChange(app, value)}
                    onPaymentsChange={(value, recalculatePayments = true) =>
                      onValueChangeInternal(app, objectKey, type, value, { recalculatePayments })
                    }
                    readOnlyMode={readOnlyMode}
                    monthsInAbsoluteTerms={true}
                  />
                ) : type === 'text' ? (
                  <>
                    <AutoGrowTextArea
                      autoComplete="off"
                      tabIndex={appIndex * totalFieldsCount + tabIndex}
                      className="m-0"
                      as={as || (percentageOfField ? 'input' : 'textarea')}
                      type={'text'}
                      style={percentageOfField ? { width: '70%' } : {}}
                      size="sm"
                      disabled={readOnlyMode}
                      rows={1}
                      list={`${objectKey}-list`}
                      value={app[objectKey]}
                      onChange={e => onValueChangeInternal(app, objectKey, type, e.target.value, { percentageOfField })}
                    />
                    {dataListOptions?.length > 0 && (
                      <datalist id={`${objectKey}-list`}>
                        {dataListOptions.map(option => (
                          <option key={option} value={option} />
                        ))}
                      </datalist>
                    )}
                  </>
                ) : (
                  <>
                    <CurrencyInput
                      tabIndex={appIndex * totalFieldsCount + tabIndex}
                      className="m-0"
                      style={percentageOfField ? { width: '70%' } : {}}
                      size="sm"
                      disabled={readOnlyMode}
                      value={app[objectKey]}
                      onChange={number => onValueChangeInternal(app, objectKey, type, number, { percentageOfField })}
                    />
                    {percentageOfField && (
                      <PercentageInput
                        className="m-0 ms-1"
                        hint="%"
                        size="sm"
                        style={{ width: '30%' }}
                        disabled={readOnlyMode}
                        value={app[`${objectKey}-percentage`]}
                        onChange={e =>
                          onValueChangeInternal(app, objectKey, 'dependent-percentage', e.target.value, {
                            percentageOfField
                          })
                        }
                      />
                    )}
                  </>
                )
              ) : (
                <span className="px-2">
                  {type === 'percentage'
                    ? formatPercentage(getPercentageValue(allFields, objectKey, app[objectKey]))
                    : formatCurrency(app[objectKey])}
                </span>
              )}
              {extraInfo && app?.[extraInfo?.key] && <Summary data={app?.[extraInfo?.key]} extraInfo={extraInfo} />}
            </div>
          )}
        </td>
      ))}
    </tr>
  );
};

export default DataRow;
