import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import RealEstateIRR from '../components/calculators/Irr';
import CachedResponse from '../components/common/CachedResponse';
import Header from '../components/common/Header';
import { UserContext } from '../context/UserContext';
import RSUOptionsCalculator from '../components/calculators/RSUOptionsCalculator';

const NonAuthenticatedApps = () => {
  const { isUserLoggedIn } = useContext(UserContext);
  if (isUserLoggedIn) {
    return <Redirect from="/login" to="/" />;
  }

  return (
    <>
      <Header />
      <Switch>
        <Route path="/home/realestate-irr">
          <CachedResponse
            listEndpoint={'comps'}
            requestBody={{ filter: { customer: false } }}
            responseModifier={response => response[0]}
            render={compTable => <RealEstateIRR adminCompTable={compTable} />}
          />
        </Route>
        <Route path="/home/rsu-options-calculator">
          <CachedResponse
            listEndpoint={'comps'}
            requestBody={{ filter: { customer: false } }}
            responseModifier={response => response[0]}
            render={compTable => <RSUOptionsCalculator adminCompTable={compTable} />}
          />
        </Route>
      </Switch>
    </>
  );
};

export default NonAuthenticatedApps;
