import { snakeCase } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { InfoCircle, Pencil, Plus, Trash } from 'react-bootstrap-icons';
import { compareStrategies } from '../../helpers/compareStrategies';
import { keysToIgnoreWhileComparing, transferTypeOptions } from '../../helpers/constants';
import useLocalization from '../../hooks/useLocalization';
import AppTable from './app-table/AppTable';
import ObjectDifferencesTable from './ObjectDifferenceTable';

const getIcon = result => {
  const ResultToIconMap = {
    Added: Plus,
    Deleted: Trash,
    Updated: Pencil
  };

  const Icon = ResultToIconMap[result];

  return Icon ? <Icon size={16} className="mx-2" /> : '';
};

const getBgColor = result => {
  const ResultToIconMap = {
    Added: 'success',
    Deleted: 'danger',
    Updated: 'primary-light'
  };

  const color = ResultToIconMap[result];

  return color ? `bg-${color} bg-opacity-10` : '';
};

const keyMap = {
  fixedIncomeAssets: 'fixed_income',
  oneOffChanges: 'one_off_change',
  customCashflows: 'cashflow',
  investments: 'reinvestments'
};

const getObjectLabel = (item, category, translate) => {
  if (category === 'investments') {
    const firstItem = item.comparisons.map(c => c.object).filter(Boolean)[0];
    const label = transferTypeOptions.find(o => o.value === firstItem.transferValueType)?.label;
    console.log({ label, firstItem });
    return `Type: ${translate(snakeCase(label))}, Frequency: ${firstItem.frequency}, Value: ${firstItem.value}`;
  }
  return item.identifier;
};

const ObjectDifferencePopup = ({
  show,
  onHide,
  objects = [],
  identifier,
  comparisonStrategies = [],
  onStrategyClick
}) => {
  const { translate } = useLocalization();

  return (
    <Modal size="xl" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <h6 className="mb-0">
          {translate('differences')} ({identifier})
        </h6>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <ObjectDifferencesTable
          objectsToCompare={objects}
          comparisonStrategies={comparisonStrategies}
          onStrategyClick={onStrategyClick}
        />
      </Modal.Body>
    </Modal>
  );
};

const StrategyDifferences = ({ customer, comparisonStrategies = [] }) => {
  const { translate } = useLocalization();
  const [objectDifferenceMeta, setObjectDifferenceMeta] = useState();

  const comparedData = useMemo(() => {
    if (comparisonStrategies.length < 2) {
      return null;
    }

    return compareStrategies(comparisonStrategies, keysToIgnoreWhileComparing);
  }, [comparisonStrategies]);

  const hasChanges = useMemo(() => {
    return comparedData && Object.keys(comparedData).some(k => comparedData[k]?.length);
  }, [comparedData]);

  const onComparisonClick = (category, item, comparison) => {
    setObjectDifferenceMeta({
      objects: item?.comparisons?.map(c => c.object),
      identifier: getObjectLabel(item, category, translate),
      category
    });
  };

  const onStrategyInObjectDifferenceTableClick = strategyIndex => {
    const strategy = comparisonStrategies[strategyIndex];
    const category = objectDifferenceMeta?.category;
    const objectId = objectDifferenceMeta?.objects?.[strategyIndex]?._id;

    const link = `/admin/manage-users/customer?customerToView=${customer?._id}&strategy=${strategy?._id}&category=${category}&id=${objectId}`;
    window.open(link, '_blank');
  };

  return (
    <>
      {comparedData ? (
        <AppTable striped bordered hover>
          <thead>
            <tr className="bg-dark text-white">
              <th style={{ minWidth: 200 }}></th>
              {comparisonStrategies.map(strategy => (
                <th style={{ minWidth: 200 }} key={strategy?._id}>
                  <div className="d-flex align-items-center justify-content-between">
                    {strategy.name} {strategy.isSandboxed ? `(${translate('sandbox')})` : `(${translate('main')})`}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!hasChanges ? (
              <tr>
                <td colSpan={comparisonStrategies.length + 1} className="fw-bold text-center">
                  {translate('nothing_to_show')}
                </td>
              </tr>
            ) : (
              <>
                {Object.keys(comparedData).map(category =>
                  comparedData[category]?.length > 0 ? (
                    <React.Fragment key={category}>
                      <tr>
                        <td colSpan={comparisonStrategies.length + 1} className="fw-bold bg-primary-light">
                          {translate(keyMap[category] || snakeCase(category))}
                        </td>
                      </tr>
                      {comparedData[category].map(item => (
                        <tr key={item.identifier}>
                          <td className="bg-white">
                            <b>{getObjectLabel(item, category, translate)}</b>
                          </td>
                          {item.comparisons.map((comparison, index) => (
                            <td
                              key={index}
                              className={`hover fw-bold`}
                              onClick={() => onComparisonClick(category, item, comparison)}
                            >
                              {getIcon(comparison.result)}
                              {translate(snakeCase(comparison.result))}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </React.Fragment>
                  ) : (
                    <></>
                  )
                )}
                <ObjectDifferencePopup
                  show={Boolean(objectDifferenceMeta)}
                  onHide={() => setObjectDifferenceMeta()}
                  identifier={objectDifferenceMeta?.identifier}
                  objects={objectDifferenceMeta?.objects}
                  comparisonStrategies={comparisonStrategies}
                  onStrategyClick={onStrategyInObjectDifferenceTableClick}
                />
              </>
            )}
          </tbody>
        </AppTable>
      ) : (
        <div className="border rounded h-100 d-flex align-items-center justify-content-center">
          <h6 className="text-center xxlarge text-muted fw-bold">
            <InfoCircle className="text-dark mx-2" size={24} />
            {translate('nothing_to_show_compare_strategy')}
          </h6>
        </div>
      )}
    </>
  );
};

export default StrategyDifferences;
