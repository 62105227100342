import { irr as IRR } from 'node-irr';
import { computeTaxesForCalculators } from './helpers';
import { getMaterialIndexCostForPayment } from '../admin/manage-users/customer/strategies/helper';

const Finance = require('tvm-financejs');
const { FV, PMT } = new Finance();

export const combinedIRRFormula = (
  askedPrice,
  {
    additionalComments,
    areaInSquareMeters,
    maximalMortgageAvailable,
    onlyApartmentWhileBuying,
    onlyApartmentWhileSelling,
    mortgagePercentage,
    valueOfApartmentToday,
    monthlyRent, // post contruction
    rentIncrease,
    //this is in months
    estimatedTimeForFinishingContruction,
    additionalCostsForMakingApartmentReadyForRent,
    downPayment,
    remainingPayment, //oneFinalPayment or gradualPayment
    yearlyMaterialCostIndexRise,
    expectedAppreciation,
    //this is in months
    realEstateHoldingPeriodInYears: realEstateHoldingPeriodInMonths,
    realtorCostsInPercentageTermsPreVat,
    vat,
    relatedCostsLawyerAppraiserEtc,
    mortgageInterest,
    mortgageTimeToMaturityInMonths,
    taxOnRent,
    multiplier = 0.4,
    compTable,
    paperApartmentPayments = [],
    useNewIRR = false
  },
  returnIRR
) => {
  paperApartmentPayments = paperApartmentPayments.filter(p => p.month && p.value);
  const averageMonthlyRent = compute_averageMonthlyRent(
    monthlyRent,
    rentIncrease,
    realEstateHoldingPeriodInMonths - estimatedTimeForFinishingContruction,
    estimatedTimeForFinishingContruction
  );

  const sellingPrice = compute_sellingPrice(
    askedPrice,
    expectedAppreciation,
    getApartmentAppreciation(
      askedPrice,
      valueOfApartmentToday,
      estimatedTimeForFinishingContruction,
      expectedAppreciation
    ),
    estimatedTimeForFinishingContruction,
    realEstateHoldingPeriodInMonths
  );

  const realtorCost = compute_realtorCost(askedPrice, realtorCostsInPercentageTermsPreVat);

  const { buyingTax, sellingTax } = computeTaxesForCalculators(
    sellingPrice,
    askedPrice,
    onlyApartmentWhileBuying,
    onlyApartmentWhileSelling,
    compTable,
    additionalCostsForMakingApartmentReadyForRent + realtorCost + relatedCostsLawyerAppraiserEtc
  );

  const mortgageToBeTakenDependingOnSeveralOptions = compute_mortgageToBeTakenDependingOnSeveralOptions(
    mortgagePercentage,
    askedPrice,
    maximalMortgageAvailable
  );

  const equityForTakingMortgage = compute_equityForTakingMortgage(
    askedPrice,
    mortgageToBeTakenDependingOnSeveralOptions
  );

  const costOfRealtorBuyerAndAllRelatedCosts = compute_costOfRealtorBuyerAndAllRelatedCosts(
    realtorCost,
    relatedCostsLawyerAppraiserEtc,
    buyingTax
  );

  const totalEquityToPutInTheDeal = compute_totalEquityToPutInTheDeal(
    equityForTakingMortgage,
    costOfRealtorBuyerAndAllRelatedCosts
  );

  let maxEquityThatCanBeUsed = equityForTakingMortgage;

  let maxMortgageMonthlyPayment = 0;
  let totalMortagePaid = 0;
  let mortgageLeftAtTheEndPeriodOfHolding = 0;

  const takeMortgage = (mortgageAmount, mortgageTimePeriodInMonths) => {
    const mortgageMonthlyPayment = compute_mortgageMonthlyPayment(
      mortgageInterest,
      mortgageTimeToMaturityInMonths,
      mortgageAmount
    );

    maxMortgageMonthlyPayment += mortgageMonthlyPayment;
    totalMortagePaid += mortgageMonthlyPayment * mortgageTimePeriodInMonths;
    mortgageLeftAtTheEndPeriodOfHolding += compute_mortgageLeftAtTheEndPeriodOfHolding(
      mortgageInterest,
      mortgageTimePeriodInMonths,
      mortgageMonthlyPayment,
      mortgageAmount
    );
  };

  let totalMoneySpendWhileBuying = 0;
  const downPaymentAmount = downPayment * askedPrice; //Adding down payment.

  let cashReducedFromEquityForDownpayment =
    maxEquityThatCanBeUsed >= downPaymentAmount ? downPaymentAmount : maxEquityThatCanBeUsed;
  maxEquityThatCanBeUsed -= cashReducedFromEquityForDownpayment;

  const remainingDownPaymentAfterPayingFromEquity = downPaymentAmount - cashReducedFromEquityForDownpayment;

  //initial down payment
  if (remainingDownPaymentAfterPayingFromEquity > 0) {
    //mortgage taken so calculate pmts for realestateholding period
    takeMortgage(remainingDownPaymentAfterPayingFromEquity, realEstateHoldingPeriodInMonths + 1);
  }

  totalMoneySpendWhileBuying += downPaymentAmount;

  //paper apartment payments
  paperApartmentPayments.forEach(p => {
    const paymentAmount = p.value || 0;
    let paymentCashReducedFromEquity = maxEquityThatCanBeUsed >= paymentAmount ? paymentAmount : maxEquityThatCanBeUsed;
    maxEquityThatCanBeUsed -= paymentCashReducedFromEquity;

    let remainingPayment = paymentAmount - paymentCashReducedFromEquity;
    if (remainingPayment > 0) {
      takeMortgage(remainingPayment, realEstateHoldingPeriodInMonths - (p.month || 0) + 1);
    }
  });

  const totalPaymentsDone = paperApartmentPayments.reduce((amount, p) => (p.value || 0) + amount, 0);

  let remainingAmountToPay = askedPrice - downPaymentAmount - totalPaymentsDone;

  //when there are paper payments, gradual payments would start after the final Payment
  const farthestPaymentMonth =
    paperApartmentPayments.length > 0 ? Math.max(...paperApartmentPayments.map(p => p.month)) - 1 : 0;
  let totalAccumulatedIndexCost = 0;

  const installmentTimePeriodInMonths = estimatedTimeForFinishingContruction - (farthestPaymentMonth || 0);

  const numberOfInstallments = remainingPayment === 'oneFinalPayment' ? 1 : installmentTimePeriodInMonths / 12;
  const baseInstallment = remainingAmountToPay / numberOfInstallments;

  const numberOfYearsBetweenPayments = remainingPayment === 'oneFinalPayment' ? installmentTimePeriodInMonths / 12 : 1;
  const doesFinalInstallmentEndBeforeYearEnd = numberOfInstallments % 1 !== 0;
  for (let i = 0; i < numberOfInstallments; i++) {
    const isFinalInstallmentInPartialYear =
      doesFinalInstallmentEndBeforeYearEnd && i === Math.floor(numberOfInstallments); //if doesFinalInstallmentEndBeforeYearEnd then last year

    const installmentToConsider = isFinalInstallmentInPartialYear
      ? (numberOfInstallments % 1) * baseInstallment
      : baseInstallment;

    let indexCost =
      remainingAmountToPay * (Math.pow(1 + multiplier * yearlyMaterialCostIndexRise, numberOfYearsBetweenPayments) - 1);

    let actualInstallment = baseInstallment + indexCost;
    totalMoneySpendWhileBuying += actualInstallment;
    totalAccumulatedIndexCost += indexCost;

    const amountPaidFromEquity =
      maxEquityThatCanBeUsed >= installmentToConsider ? installmentToConsider : maxEquityThatCanBeUsed;
    maxEquityThatCanBeUsed -= amountPaidFromEquity;
    let remainingInstallment = installmentToConsider - amountPaidFromEquity;

    if (remainingInstallment > 0) {
      let yearsPassed =
        (farthestPaymentMonth || 0) / 12 +
        numberOfYearsBetweenPayments * (i + (isFinalInstallmentInPartialYear ? 0 : 1));

      if (isFinalInstallmentInPartialYear) {
        yearsPassed = yearsPassed + ((installmentTimePeriodInMonths / 12) % 1); //second part is the final year which is not complete
      }

      const remainingTimePeriodInYears = realEstateHoldingPeriodInMonths / 12 - yearsPassed;
      takeMortgage(remainingInstallment, remainingTimePeriodInYears * 12);
    }

    remainingAmountToPay -= installmentToConsider;
  }

  const costOfVacancyAndAnnualRepairs = compute_costOfVacancyAndAnnualRepairs(monthlyRent);

  const totalLoanDecrease = compute_totalLoanDecrease(
    mortgageToBeTakenDependingOnSeveralOptions,
    mortgageLeftAtTheEndPeriodOfHolding
  );

  const totalIncomeFromRent = compute_totalIncomeFromRent(
    averageMonthlyRent,
    estimatedTimeForFinishingContruction,
    realEstateHoldingPeriodInMonths,
    taxOnRent,
    costOfVacancyAndAnnualRepairs
  );

  const totalDealCost = compute_totalDealCost(totalEquityToPutInTheDeal, mortgageToBeTakenDependingOnSeveralOptions);
  const profitFromSellingBeforeTax = compute_profitFromSellingBeforeTax(
    sellingPrice,
    totalEquityToPutInTheDeal,
    mortgageLeftAtTheEndPeriodOfHolding,
    additionalCostsForMakingApartmentReadyForRent,
    totalAccumulatedIndexCost
  );
  const profitFromSellingAfterTax = compute_profitFromSellingAfterTax(profitFromSellingBeforeTax, sellingTax);
  const totalIncomeFromRentMinusTotalMonthlyPayments = compute_totalIncomeFromRentMinusTotalMonthlyPayments(
    totalIncomeFromRent,
    totalMortagePaid
  );
  const totalNetProfit = compute_totalNetProfit(
    profitFromSellingAfterTax,
    totalIncomeFromRentMinusTotalMonthlyPayments,
    totalLoanDecrease
  );
  const totalRoi = compute_totalRoi(totalNetProfit, totalEquityToPutInTheDeal);
  const pricePerSquareMeter = compute_pricePerSquareMeter(totalMoneySpendWhileBuying, areaInSquareMeters);
  const irr_old = compute_iir(totalRoi, realEstateHoldingPeriodInMonths);

  //Add all cashflows here for calculating irr

  //1. initial cashflow
  const initialInvestment = costOfRealtorBuyerAndAllRelatedCosts + cashReducedFromEquityForDownpayment;
  const allCashflows = [-initialInvestment];

  const cashflowBreakdown = {
    Begin: [
      getBreakdownObject('equity', -cashReducedFromEquityForDownpayment),
      getBreakdownObject('cost_of_retailer_lawyer', -costOfRealtorBuyerAndAllRelatedCosts)
    ]
  };

  //2. cashflow for each year
  //if mortgage is taken for paying initial downpayment, we add mortgage payments also
  let mortagePayments = [];
  if (remainingDownPaymentAfterPayingFromEquity > 0) {
    mortagePayments.push(
      compute_mortgageMonthlyPayment(
        mortgageInterest,
        mortgageTimeToMaturityInMonths,
        remainingDownPaymentAfterPayingFromEquity
      )
    );
  }

  let maxEquityAvailable = equityForTakingMortgage;
  let remainingEquity = maxEquityAvailable <= downPaymentAmount ? 0 : maxEquityAvailable - downPaymentAmount;

  let remainingAmountAfterDownpayment = askedPrice - downPaymentAmount;

  //if payment type is one time, we pay all the remainingAmountAfterDownpayment and (material cost index rise on debt) at once after construction ends
  //if cash is available in equity, we first pay using cash, then we take mortgage for remaining payment amount (if any) every year
  const monthsOfPayments =
    remainingPayment !== 'oneFinalPayment'
      ? createPaymentMonths(estimatedTimeForFinishingContruction, farthestPaymentMonth || 0)
      : [estimatedTimeForFinishingContruction + 1];

  for (let month = 1; month <= realEstateHoldingPeriodInMonths; month++) {
    cashflowBreakdown[month] = [];

    const monthFromStartOfRent =
      month <= estimatedTimeForFinishingContruction ? month : month - estimatedTimeForFinishingContruction;

    //rent starts after construction
    let finalRent =
      month <= estimatedTimeForFinishingContruction
        ? 0
        : calculateYearlyRent(monthlyRent, rentIncrease, Math.ceil(monthFromStartOfRent / 12)) / 12;
    finalRent = finalRent * (1 - taxOnRent);

    const incomingCashflows = [finalRent];
    const outgoingCashflows = [];

    if (month === estimatedTimeForFinishingContruction + 1) {
      outgoingCashflows.push(additionalCostsForMakingApartmentReadyForRent);
      cashflowBreakdown[month].push(
        getBreakdownObject(
          'additional_costs_for_making_the_apartment_ready_for_rent_paper_apartment',
          -additionalCostsForMakingApartmentReadyForRent
        )
      );
    }

    const paymentsThisMonth = paperApartmentPayments.filter(p => p.month === month);
    paymentsThisMonth.forEach(p => {
      const paymentValue = p.value;
      const amountPaidFromEquity = remainingEquity >= paymentValue ? paymentValue : remainingEquity;
      remainingEquity -= amountPaidFromEquity;
      outgoingCashflows.push(amountPaidFromEquity);
      if (amountPaidFromEquity !== 0)
        cashflowBreakdown[month].push(getBreakdownObject('equity', -amountPaidFromEquity));

      let indexCost = getMaterialIndexCostForPayment(
        paymentValue,
        multiplier * yearlyMaterialCostIndexRise * 100,
        (month - 1) / 12
      );

      outgoingCashflows.push(indexCost);
      cashflowBreakdown[month].push(getBreakdownObject('yearly_materials_cost_index_rise', -indexCost));

      let remainingPaymentValue = paymentValue - amountPaidFromEquity;

      if (remainingPaymentValue > 0) {
        const newMortgagePaymentTakenThisYear = compute_mortgageMonthlyPayment(
          mortgageInterest,
          mortgageTimeToMaturityInMonths,
          remainingPaymentValue
        );
        mortagePayments.push(newMortgagePaymentTakenThisYear);
      }
    });

    if (monthsOfPayments.includes(month)) {
      const isFinalInstallmentInPartialYear =
        doesFinalInstallmentEndBeforeYearEnd && month - 1 === estimatedTimeForFinishingContruction; //if doesFinalInstallmentEndBeforeYearEnd then last year

      const installmentToConsider = isFinalInstallmentInPartialYear
        ? (numberOfInstallments % 1) * baseInstallment
        : baseInstallment;

      let indexCost = getMaterialIndexCostForPayment(
        installmentToConsider,
        multiplier * yearlyMaterialCostIndexRise * 100,
        (month - 1) / 12
      );

      outgoingCashflows.push(indexCost);
      cashflowBreakdown[month].push(getBreakdownObject('yearly_materials_cost_index_rise', -indexCost));

      const amountPaidFromEquity = remainingEquity >= installmentToConsider ? installmentToConsider : remainingEquity;
      remainingEquity -= amountPaidFromEquity;
      outgoingCashflows.push(amountPaidFromEquity);
      if (amountPaidFromEquity !== 0)
        cashflowBreakdown[month].push(getBreakdownObject('equity', -amountPaidFromEquity));

      let remainingInstallment = installmentToConsider - amountPaidFromEquity;
      if (remainingInstallment > 0) {
        const newMortgagePaymentTakenThisYear = compute_mortgageMonthlyPayment(
          mortgageInterest,
          mortgageTimeToMaturityInMonths,
          remainingInstallment
        );
        mortagePayments.push(newMortgagePaymentTakenThisYear);
      }

      remainingAmountAfterDownpayment -= installmentToConsider;
    }

    //add to breakdowns:
    cashflowBreakdown[month].push(getBreakdownObject('rent', finalRent));

    const totalMortgagePaymentsThisYear = mortagePayments.reduce((acc, p) => p + acc, 0);
    outgoingCashflows.push(totalMortgagePaymentsThisYear);
    cashflowBreakdown[month].push(getBreakdownObject('total_monthly_payments', -totalMortgagePaymentsThisYear));

    let netCashflow =
      incomingCashflows.reduce((acc, c) => acc + c, 0) - outgoingCashflows.reduce((acc, c) => acc + c, 0);

    //3. if last year, add remaining cash after selling
    if (month === realEstateHoldingPeriodInMonths) {
      netCashflow += sellingPrice - sellingTax - mortgageLeftAtTheEndPeriodOfHolding;

      //add to breakdowns:
      cashflowBreakdown[month].push(
        ...[
          { key: 'selling_price', value: sellingPrice },
          { key: 'selling_tax', value: -sellingTax },
          { key: 'mortgage_left_at_the_end', value: -mortgageLeftAtTheEndPeriodOfHolding }
        ].map(({ key, value }) => getBreakdownObject(key, value))
      );
    }

    allCashflows.push(netCashflow);
  }

  const irr = useNewIRR ? getNewIRR(allCashflows) : irr_old;
  if (returnIRR) {
    return irr;
  }

  return {
    mortgageToBeTakenDependingOnSeveralOptions,
    costOfVacancyAndAnnualRepairs,
    mortgageMonthlyPayment: maxMortgageMonthlyPayment,
    realtorCost,
    equityForTakingMortgage,
    costOfRealtorBuyerAndAllRelatedCosts,
    buyingTax,
    sellingPrice,
    mortgageLeftAtTheEndPeriodOfHolding,
    totalLoanDecrease,
    totalIncomeFromRent,
    totalMonthlyPayments: totalMortagePaid,
    totalEquityToPutInTheDeal,
    totalDealCost,
    stillInsufficientAmountForBuying: totalAccumulatedIndexCost,
    profitFromSellingBeforeTax,
    profitFromSellingAfterTax,
    totalIncomeFromRentMinusTotalMonthlyPayments,
    totalNetProfit,
    totalRoi,
    pricePerSquareMeter,
    irr,
    averageMonthlyRent,
    irrBreakdown: useNewIRR ? cashflowBreakdown : null,
    sellingTax
  };
};

const getNewIRR = cashflows => {
  const monthlyIRR = IRR(cashflows);
  return convertMonthlyIrrToAnnual(monthlyIRR);
};

const convertMonthlyIrrToAnnual = monthlyIrr => {
  return (1 + monthlyIrr) ** 12 - 1;
};

const getBreakdownObject = (label, cashflow) => ({ description: label, cashflow });

function calculateYearlyRent(monthlyRent, yearlyIncrease, years) {
  const initialYearlyRent = monthlyRent * 12;
  const finalYearlyRent = initialYearlyRent * Math.pow(1 + yearlyIncrease, years - 1);
  return finalYearlyRent;
}

function createPaymentMonths(estimatedTimeToFinishConstructionInMonths, paymentTimePeriodStartDelay) {
  if (estimatedTimeToFinishConstructionInMonths < 1) {
    return [];
  }

  const paymentMonths = [];
  let currentMonth = 12 + paymentTimePeriodStartDelay; // Payments start at the beginning of the second year (13th month)

  while (currentMonth <= estimatedTimeToFinishConstructionInMonths) {
    paymentMonths.push(currentMonth + 1);
    currentMonth += 12;
  }

  // Check if there is a remaining period after the last full 12 months
  if (currentMonth - 12 < estimatedTimeToFinishConstructionInMonths) {
    paymentMonths.push(estimatedTimeToFinishConstructionInMonths + 1);
  }

  return paymentMonths;
}

//total holding period is the time for each apartment is hold
//among which rentPeriodInMonths is the time for which rent actually is received
const compute_averageMonthlyRent = (startingRent, rentIncreaseYearly, rentPeriodInMonths, skipMonths = 0) => {
  const totalHoldingPeriodInMonths = skipMonths + rentPeriodInMonths;
  let totalRent = 0;
  let currentRent = startingRent;

  for (let month = 0; month < totalHoldingPeriodInMonths; month++) {
    // Collect rent only after the initial skip period is over
    if (month >= skipMonths) {
      totalRent += currentRent;
    }

    // Apply the yearly rent increase
    if ((month + 1) % 12 === 0) {
      currentRent *= 1 + rentIncreaseYearly;
    }
  }

  // Calculate average based on the entire holding period, not just collection period
  const averageMonthlyRent = totalRent / rentPeriodInMonths;
  return averageMonthlyRent;
};

const compute_pricePerSquareMeter = (targetPrice, areaInSquareMeters) => targetPrice / areaInSquareMeters;

const compute_iir = (totalRoi, realEstateHoldingPeriodInMonths) =>
  (1 + totalRoi) ** (12 / realEstateHoldingPeriodInMonths) - 1;

const compute_totalRoi = (totalNetProfit, totalEquityToPutInTheDeal) => totalNetProfit / totalEquityToPutInTheDeal;

const compute_costOfVacancyAndAnnualRepairs = monthlyRent => monthlyRent;

const compute_totalIncomeFromRent = (
  monthlyRent,
  estimatedTimeForFinishingContruction,
  realEstateHoldingPeriodInYears,
  taxOnRent,
  costOfVacancyAndAnnualRepairs
) => {
  const numberOfMonthsWithRent = realEstateHoldingPeriodInYears - estimatedTimeForFinishingContruction;
  const years = numberOfMonthsWithRent / 12;
  const totalVacancyCost = Math.ceil(years) * costOfVacancyAndAnnualRepairs;

  return (monthlyRent * numberOfMonthsWithRent - totalVacancyCost) * (1 - taxOnRent);
};
const compute_totalIncomeFromRentMinusTotalMonthlyPayments = (totalIncomeFromRent, totalMonthlyPayments) =>
  totalIncomeFromRent - totalMonthlyPayments;

const compute_totalNetProfit = (
  profitFromSellingAfterTax,
  totalIncomeFromRentMinusTotalMonthlyPayments,
  totalLoanDecrease
) => profitFromSellingAfterTax + totalIncomeFromRentMinusTotalMonthlyPayments + totalLoanDecrease;

const compute_mortgageMonthlyPayment = (
  mortgageInterest,
  mortgageTimeToMaturityInMonths,
  mortgageToBeTakenDependingOnSeveralOptions
) => -1 * PMT(mortgageInterest / 12, mortgageTimeToMaturityInMonths, mortgageToBeTakenDependingOnSeveralOptions, 0);

const compute_mortgageLeftAtTheEndPeriodOfHolding = (
  mortgageInterest,
  realEstateHoldingPeriodInMonths,
  mortgageMonthlyPayment,
  mortgageToBeTakenDependingOnSeveralOptions
) =>
  -1 *
  FV(
    mortgageInterest / 12,
    realEstateHoldingPeriodInMonths,
    -1 * mortgageMonthlyPayment,
    mortgageToBeTakenDependingOnSeveralOptions,
    0
  );

const compute_totalLoanDecrease = (mortgageToBeTakenDependingOnSeveralOptions, mortgageLeftAtTheEndPeriodOfHolding) =>
  mortgageToBeTakenDependingOnSeveralOptions - mortgageLeftAtTheEndPeriodOfHolding;

const compute_profitFromSellingBeforeTax = (
  sellingPrice,
  totalEquityToPutInTheDeal,
  mortgageLeftAtTheEndPeriodOfHolding,
  additionalCostsForMakingApartmentReadyForRent,
  totalAccumulatedIndexCost
) =>
  sellingPrice -
  totalEquityToPutInTheDeal -
  mortgageLeftAtTheEndPeriodOfHolding -
  additionalCostsForMakingApartmentReadyForRent -
  totalAccumulatedIndexCost;

const compute_profitFromSellingAfterTax = (profitFromSellingBeforeTax, sellingTax) =>
  profitFromSellingBeforeTax - sellingTax;

const compute_sellingPrice = (
  askedPrice,
  expectedAppreciation,
  appreciationDuringConstruction,
  expectedTimeToGetApartmentReconstructedInMonths,
  realEstateHoldingPeriodInMonths
) => {
  let value = 0;
  const monthlyAppreciationDuringConstruction = (1 + appreciationDuringConstruction) ** (1 / 12) - 1;
  const monthlyExpectedAppreciation = (1 + expectedAppreciation) ** (1 / 12) - 1;

  if (realEstateHoldingPeriodInMonths <= expectedTimeToGetApartmentReconstructedInMonths) {
    value = askedPrice * (1 + monthlyAppreciationDuringConstruction) ** realEstateHoldingPeriodInMonths;
  } else {
    const timeAfterConstruction = realEstateHoldingPeriodInMonths - expectedTimeToGetApartmentReconstructedInMonths;
    const valueAtEndOfConstruction =
      askedPrice * (1 + monthlyAppreciationDuringConstruction) ** expectedTimeToGetApartmentReconstructedInMonths;

    value = valueAtEndOfConstruction * (1 + monthlyExpectedAppreciation) ** timeAfterConstruction;
  }

  return value;
};

const compute_totalEquityToPutInTheDeal = (equityForTakingMortgage, costOfRealtorBuyerAndAllRelatedCosts) =>
  equityForTakingMortgage + costOfRealtorBuyerAndAllRelatedCosts;

const compute_totalDealCost = (totalEquityToPutInTheDeal, mortgageToBeTakenDependingOnSeveralOptions) =>
  totalEquityToPutInTheDeal + mortgageToBeTakenDependingOnSeveralOptions;

const compute_costOfRealtorBuyerAndAllRelatedCosts = (realtorCost, relatedCostsLawyerAppraiserEtc, buyingTax) =>
  realtorCost + relatedCostsLawyerAppraiserEtc + buyingTax;

const compute_equityForTakingMortgage = (targetPrice, mortgageToBeTakenDependingOnSeveralOptions) =>
  targetPrice - mortgageToBeTakenDependingOnSeveralOptions;

const compute_mortgageToBeTakenDependingOnSeveralOptions = (
  mortgagePercentage,
  inputPrice,
  maximalMortgageAvailable
) => {
  let askedMortgage = inputPrice * mortgagePercentage;
  if (askedMortgage > maximalMortgageAvailable) return maximalMortgageAvailable;
  return askedMortgage;
};

const compute_realtorCost = (targetPrice, realtorCostsInPercentageTermsIncludingVat) =>
  targetPrice * realtorCostsInPercentageTermsIncludingVat;

const findTargetPriceInRange = (lower, higher, targetIIR, otherParams, depthOfSlicing) => {
  const increments = (higher - lower) / depthOfSlicing;
  let testPrices = [];
  let priceAtInterval = lower;
  while (true) {
    testPrices.push(priceAtInterval);
    priceAtInterval += increments;
    if (priceAtInterval > higher) break;
  }
  let iirAtThesePrices = testPrices.map(price => combinedIRRFormula(price, otherParams, true));
  for (let i = 0; i < iirAtThesePrices.length - 1; i++) {
    let current = iirAtThesePrices[i];
    let next = iirAtThesePrices[i + 1];
    if (Math.abs(targetIIR - current) < 0.001) return testPrices[i];
    if (Math.abs(targetIIR - next) < 0.001) return testPrices[i + 1];
    if (numberBetweenRange(current, next, targetIIR))
      return findTargetPriceInRange(testPrices[i], testPrices[i + 1], targetIIR, otherParams, depthOfSlicing);
  }
  return Number.NaN;
};

const numberBetweenRange = (extreme1, extreme2, value) => {
  const lower = extreme1 > extreme2 ? extreme2 : extreme1;
  const higher = extreme1 > extreme2 ? extreme1 : extreme2;
  const returnValue = value >= lower && value <= higher;
  return returnValue;
};

const getApartmentAppreciation = (
  askedPrice,
  valueOfApartmentToday,
  timeToCompleteReconstructionInMonths,
  expectedYearlyAppreciation
) => {
  const monthlyAppreciation = (1 + expectedYearlyAppreciation) ** (1 / 12) - 1;
  const valueAtEndOfConstruction =
    valueOfApartmentToday * (1 + monthlyAppreciation) ** timeToCompleteReconstructionInMonths;
  const yearlyAppreciation =
    Math.pow(valueAtEndOfConstruction / askedPrice, 12 / timeToCompleteReconstructionInMonths) - 1;
  return yearlyAppreciation;
};

export const computeTargetPrice = (startPrice, targetIIR, otherParams) => {
  const depthOfSlicing = 200;
  const multiplierStep = 0.0001;
  const canGuessAsLowAsNTimes = 10; // For a value of 100, a guess as low as 25 will converge, others will fail.
  let currentMultiplier = 1,
    previousIRR = combinedIRRFormula(startPrice, otherParams, true);
  while (true) {
    let nextTestPrice = startPrice * (currentMultiplier - multiplierStep);
    let nextIRR = combinedIRRFormula(nextTestPrice, otherParams, true);

    if (numberBetweenRange(previousIRR, nextIRR, targetIIR)) {
      return findTargetPriceInRange(
        nextTestPrice,
        startPrice * currentMultiplier,
        targetIIR,
        otherParams,
        depthOfSlicing
      );
    }
    previousIRR = nextIRR;
    currentMultiplier -= multiplierStep;
    if (currentMultiplier <= 0) break;
  }
  currentMultiplier = 1;
  previousIRR = combinedIRRFormula(startPrice, otherParams, true);
  while (true) {
    let nextTestPrice = startPrice * (currentMultiplier + multiplierStep);
    let nextIRR = combinedIRRFormula(nextTestPrice, otherParams, true);

    if (numberBetweenRange(previousIRR, nextIRR, targetIIR)) {
      return findTargetPriceInRange(
        startPrice * currentMultiplier,
        nextTestPrice,
        targetIIR,
        otherParams,
        depthOfSlicing
      );
    }
    currentMultiplier += multiplierStep;
    previousIRR = nextIRR;
    if (currentMultiplier > canGuessAsLowAsNTimes) break;
  }
  return 0;
};
