import { snakeCase } from 'lodash';
import React, { useMemo } from 'react';
import { ArrowUpRightCircle } from 'react-bootstrap-icons';
import { findAllKeysInObjects, findDifferencesInObjects, replaceIdWithName } from '../../helpers/compareStrategies';
import { keysToIgnoreWhileComparing, months, monthSmall, transferTypeOptions } from '../../helpers/constants';
import { camelToSentenceCase, isNullOrUndefined, isValidMongoId } from '../../helpers/global';
import useLocalization from '../../hooks/useLocalization';
import AppTable from './app-table/AppTable';
import { formatCurrency } from '../admin/manage-users/customer/strategies/helper';

const fieldsToReplace = {
  linkedBankAccountName: 'linkedBankAccount',
  linkedApartmentName: 'linkedApartment',
  onlyApartment: 'is_only_apartment',
  isSellAutomaticallyCalculated: 'selling_tax_calculated_automatically',
  isBuyAutomaticallyCalculated: 'buying_tax_calculated_automatically',
  buy: 'buying_tax',
  sell: 'selling_tax',
  relatedAssets: 'linked_asset',
  cashflowChanges: 'one_off_change',
  fromInstrumentName: 'transfer_applied_to',
  toInstrumentName: 'balance_with',
  transferValueType: 'transfer_type',
  yearlyMaterialCostIndexRise: 'yearly_materials_cost_index_rise_comp',
  paperApartmentPayments: 'payments',
  affectsRiskManagement: 'is_salary'
};

const getKeyToShow = (key, translate) => {
  const originalKey = fieldsToReplace[key] || key;
  return translate(snakeCase(originalKey)) || camelToSentenceCase(originalKey);
};

function containsExactMonth(key) {
  return /\bmonth\b/.test(key.toLowerCase());
}

const getValueToShow = (key, object, strategy, translate) => {
  let newValue = key ? object?.[key] : object;

  if (key === 'name') {
    console.log(newValue);
  }

  if (isNullOrUndefined(newValue)) return;

  if (isValidMongoId(newValue)) {
    return replaceIdWithName(strategy, newValue) || newValue;
  }

  if (newValue && !Array.isArray(newValue) && typeof newValue === 'object') {
    return (
      <div class="mt-1 p-2 border rounded bg-light">
        {Object.keys(newValue).map(subKey => {
          let subKeyToShow = getKeyToShow(subKey, translate);
          return (
            <div>
              <b>{subKeyToShow}</b> : {getValueToShow(subKey, newValue, strategy, translate)}
            </div>
          );
        })}
      </div>
    );
  }

  if (containsExactMonth(camelToSentenceCase(key)) && newValue !== null && newValue !== undefined) {
    return months[newValue];
  }

  if (key === 'transferValueType') {
    return transferTypeOptions.find(o => o.value === newValue)?.label;
  }

  if (Array.isArray(newValue)) {
    return newValue.map(v => getValueToShow('', v, strategy, translate));
  }

  if ('boolean' === typeof newValue) {
    return newValue ? 'Yes' : 'No';
  }

  if (typeof newValue === 'number') {
    return formatCurrency(newValue);
  }

  return newValue?.toString() ? camelToSentenceCase(newValue?.toString()) : '';
};

const ObjectDifferencesTable = ({ comparisonStrategies = [], objectsToCompare = [], onStrategyClick }) => {
  const { translate } = useLocalization();

  const changedKeys = useMemo(() => {
    return findDifferencesInObjects(objectsToCompare, comparisonStrategies, keysToIgnoreWhileComparing);
  }, [objectsToCompare]);

  const allKeys = useMemo(() => {
    let allKeys = findAllKeysInObjects(objectsToCompare, keysToIgnoreWhileComparing);

    const changedKeysSorted = [...new Set(changedKeys.flatMap(c => c))].sort();
    allKeys = allKeys.filter(k => !changedKeysSorted.includes(k));
    allKeys = [...changedKeysSorted, ...allKeys];
    return allKeys;
  }, [objectsToCompare, changedKeys]);

  return (
    <AppTable striped bordered hover>
      <thead>
        <tr className="bg-dark text-white">
          <th style={{ minWidth: 200 }}></th>
          {comparisonStrategies.map((strategy, index) => (
            <th
              style={{ minWidth: 200 }}
              key={strategy?._id}
              className="hover-light"
              onClick={() => {
                onStrategyClick(index);
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                {strategy.name} {strategy.isSandboxed ? `(${translate('sandbox')})` : `(${translate('main')})`}
                <ArrowUpRightCircle />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {allKeys.map(key => (
          <tr key={key}>
            <td className="bg-white">{getKeyToShow(key, translate)}</td>
            {comparisonStrategies.map((s, index) => (
              <td
                style={{
                  maxWidth: 100,
                  wordBreak: 'break-word',
                  overflow: 'hidden',
                  whiteSpace: 'normal'
                }}
                key={s._id}
                className={changedKeys?.[index]?.includes(key) ? 'bg-primary-light' : ''}
              >
                {getValueToShow(key, objectsToCompare?.[index], s, translate) || '-'}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </AppTable>
  );
};

export default ObjectDifferencesTable;
