import React, { useMemo } from 'react';
import { Button, FormControl, FormSelect } from 'react-bootstrap';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import { formatCurrency, formatPercentage } from '../../components/admin/manage-users/customer/strategies/helper';
import SwitchInput from '../../components/common/SwitchInput';
import UnderlineButton from '../../components/common/UnderlineButton';
import AppTable from '../../components/common/app-table/AppTable';
import { transferFrequencyOptions } from '../../helpers/constants';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel } from '../../helpers/global';
import useAuth from '../../hooks/useAuth';
import useLocalization from '../../hooks/useLocalization';

const getDepositsHeaders = (translate, assetOptions = []) => [
  {
    name: translate('source'),
    key: 'source',
    type: 'dropdown',
    options: [{ option: '', value: '' }, ...assetOptions.map(a => ({ option: a.name, value: a._id }))]
  },
  {
    name: translate('frequency'),
    key: 'frequency',
    type: 'dropdown',
    options: transferFrequencyOptions.map(o => ({ option: o.label, value: o.value }))
  },
  {
    name: translate('value'),
    key: 'value',
    type: 'number',
    format: 'currency'
  }
];

const Deposits = ({ deposits = [], onDepositChange, onDepositAddClick, onDepositDeleteClick, assetOptions }) => {
  const { langCode, translate } = useLocalization();
  const depositsHeaders = useMemo(() => getDepositsHeaders(translate, assetOptions), [langCode, assetOptions]);
  const { user } = useAuth();

  const onChange = (deposit, field, type, format, value) => {
    deposit[field] = value;

    onDepositChange && onDepositChange(deposits.map(p => (p.id === deposit.id ? { ...deposit } : p)));
  };

  return (
    <>
      <AppTable className="mb-0 w-100">
        <thead>
          <tr className="text-white bg-dark">
            {depositsHeaders.map(({ name }) => (
              <th style={{ minWidth: 100 }} key={name}>
                {name}
              </th>
            ))}
            {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
              <th style={{ minWidth: 70 }}>{translate('actions')}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {deposits.length > 0 ? (
            <>
              {deposits.map(payment => (
                <tr key={payment.id}>
                  {depositsHeaders.map(({ name, type, options, key, format }) => (
                    <td valign="middle" align="center" className="bg-white p-0" key={name}>
                      {type === 'dropdown' && (
                        <FormSelect
                          className="mb-0 border-0"
                          autoComplete="off"
                          size="sm"
                          value={payment[key]}
                          onChange={e => {
                            onChange(payment, key, type, format, e.target.value);
                          }}
                        >
                          {options.map(o => (
                            <option key={o.value} value={o.value}>
                              {o.option}
                            </option>
                          ))}
                        </FormSelect>
                      )}
                      {type === 'number' && (
                        <FormControl
                          className="mb-0 border-0"
                          autoComplete="off"
                          type="text"
                          size="sm"
                          value={format === 'currency' ? formatCurrency(payment[key]) : formatPercentage(payment[key])}
                          onChange={e => {
                            let inputValue = Number(
                              e.target.value.replaceAll(format === 'currency' ? ',' : '%', '').trim() || 0
                            );
                            if (Number.isNaN(inputValue)) return;
                            //call on change
                            onChange(payment, key, type, format, inputValue);
                          }}
                        />
                      )}
                      {type === 'switch' && (
                        <SwitchInput
                          onChange={value => onChange(payment, key, type, format, value)}
                          value={payment[key]}
                        />
                      )}
                    </td>
                  ))}
                  {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
                    <td className="p-0">
                      <div className="text-center">
                        <Button
                          variant="outline-danger"
                          className="px-1 py-0 "
                          onClick={() => onDepositDeleteClick(payment)}
                        >
                          <Trash size={9} />
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={depositsHeaders.length + 1}>
                <h6 className="text-muted text-center mb-0 smallFont">{translate('nothing_to_show')}</h6>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
      <div className="text-end">
        {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && (
          <UnderlineButton
            fontSize="smallFont"
            variant="success"
            Icon={PlusCircle}
            text={translate('new')}
            onClick={onDepositAddClick}
          />
        )}
      </div>
    </>
  );
};

export default Deposits;
