import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Card, Dropdown } from 'react-bootstrap';
import {
  ArrowCounterclockwise,
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  Building,
  Calendar2Date,
  ExclamationTriangle,
  EyeFill,
  EyeSlashFill,
  FileSpreadsheet,
  Folder2,
  GraphUpArrow,
  Link,
  Pen,
  Person,
  PersonBoundingBox,
  Plus,
  Share,
  Telephone,
  ThreeDotsVertical,
  Trash,
  Whatsapp
} from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { makeApiRequests } from '../../../../helpers/api';
import {
  ENDPOINTS,
  IRR_CALCULATOR_KEY,
  PAPER_CALCULATOR_KEY,
  PINUI_BINUI_CALCULATOR_KEY,
  RSU_OPTIONS_KEY
} from '../../../../helpers/constants';
import { isAdmin } from '../../../../helpers/global';
import { isRtl } from '../../../../helpers/localization';
import useAuth from '../../../../hooks/useAuth';
import DropdownSelector from '../../../common/DropdownSelector';
import FileUploadButton from '../../../common/FileUploadButton';
import SmallDropdownToggle from '../../../common/SmallDropdownToggle';
import UnderlineButton from '../../../common/UnderlineButton';
import UndoRedoButton from '../../../common/UndoRedoButton';
import CircularProgressBar from '../../../common/circular-progress';
import CustomerStrategies from './strategies/Strategies';
import RSUOptions from '../../../calculators/RSUOptionsCalculator';
import PaperApartmentIRR from '../../../calculators/PaperApartment';
import PinuiBinuiIRR from '../../../calculators/PinuiBinui';
import RealEstateIRR from '../../../calculators/Irr';

const ParentCustomerActions = ({
  onEditClick,
  onDeleteClick,
  onArchiveClick,
  onViewCRMContactClick,
  onUploadFileChange,
  onViewAppointmentClick,
  isFileUploadingToCustomer,
  onViewFilesClick,
  makePhoneCall,
  onLogsClick,
  isArchived,
  onShareClick,
  onCustomerGeneralInfoClick,
  translate,
  activeCustomer,
  readOnlyMode,
  fromCustomerPortal,
  onAddProfileClick
}) => {
  const { user } = useAuth();

  const actionsConfig = useMemo(() => {
    const phoneNumber = activeCustomer?.phone || activeCustomer?.crmContact?.phoneNumbers?.[0];
    return [
      {
        Icon: Whatsapp,
        variant: 'outline-success',
        hide: readOnlyMode || !phoneNumber || fromCustomerPortal,
        text: translate('whatsapp'),
        href: `https://wa.me/+972${phoneNumber}`,
        hideText: true
      },
      {
        onClick: makePhoneCall,
        Icon: Telephone,
        variant: 'outline-success',
        hide: readOnlyMode || !phoneNumber || fromCustomerPortal,
        text: translate('phone'),
        hideText: true
      },

      {
        onClick: onViewFilesClick,
        Icon: Folder2,
        text: null,
        variant: 'outline-primary',
        hide: readOnlyMode,
        text: translate('view_files'),
        hideText: true
      },
      {
        onClick: onLogsClick,
        Icon: FileSpreadsheet,
        text: translate('logs'),
        variant: 'outline-primary',
        hide: false
      },
      {
        onClick: onCustomerGeneralInfoClick,
        Icon: Person,
        text: translate('general_information'),
        variant: 'outline-primary',
        hide: false
      },
      {
        onClick: onEditClick,
        Icon: Pen,
        text: null,
        variant: 'outline-primary',
        hide: readOnlyMode,
        text: translate('edit'),
        hideText: true
      },
      {
        onClick: onDeleteClick,
        Icon: Trash,
        text: null,
        variant: 'outline-danger',
        hide: !isAdmin(user?.role),
        text: translate('delete'),
        hideText: true
      },
      {
        onClick: onArchiveClick,
        Icon: isArchived ? EyeFill : EyeSlashFill,
        text: null,
        hide: readOnlyMode,
        variant: `outline-${isArchived ? 'success' : 'secondary-dark'}`,
        text: translate(isArchived ? 'unarchive' : 'archive'),
        hideText: true
      },
      {
        onClick: onShareClick,
        Icon: Share,
        text: null,
        hide: readOnlyMode,
        variant: 'dark',
        text: translate('share'),
        hideText: true
      }
    ].filter(a => (a.onClick || a.href) && !a.hide);
  }, [readOnlyMode, activeCustomer]);

  return (
    <div className="d-flex">
      {onAddProfileClick && (
        <div className="d-none d-md-flex px-2 justify-content-end border-end border-dark">
          {activeCustomer?.apartmentProfiles?.map((apartmentProfile, index) => {
            return (
              <Button
                size="sm"
                className="d-flex justify-content-center align-items-center mx-1"
                style={{ width: 35 }}
                variant="outline-dark"
                onClick={() => onAddProfileClick({ mode: 'update', data: apartmentProfile })}
              >
                <h6 className="smallFont mb-0 truncate">P{index + 1}</h6>
              </Button>
            );
          })}
          <Button
            size="sm"
            className="d-flex align-items-center mx-0 px-1"
            variant="outline-dark"
            onClick={() => onAddProfileClick({ mode: 'add', data: { type: 'paper-apartment' } })}
          >
            <Building />
            <Plus />
          </Button>
        </div>
      )}
      <div className="d-flex">
        <div className="d-none d-lg-flex align-items-center gap-1">
          <div className="d-inline-block border-right" />
          {!fromCustomerPortal && activeCustomer?.crmContact && (
            <Button
              size="sm"
              className="px-1 py-0 "
              variant="outline-dark"
              onClick={() => onViewCRMContactClick && onViewCRMContactClick(activeCustomer?.crmContact)}
            >
              CRM
            </Button>
          )}
          {!fromCustomerPortal && activeCustomer?.crmContact && (
            <Button
              size="sm"
              className="px-2 py-0 "
              onClick={() => onViewAppointmentClick && onViewAppointmentClick(activeCustomer?.crmContact)}
              variant="outline-dark"
            >
              <Calendar2Date size={13} />
            </Button>
          )}
          {!fromCustomerPortal && (
            <Button
              size="sm"
              className="px-1 py-0 "
              variant="outline-dark"
              target="blank"
              href={`${process.env.PUBLIC_URL}/customer?customerId=${activeCustomer?._id}`}
            >
              <PersonBoundingBox size={10} />
            </Button>
          )}
          {onUploadFileChange && !readOnlyMode && (
            <FileUploadButton forceShowProgress={isFileUploadingToCustomer} onUploadFileChange={onUploadFileChange} />
          )}
          {actionsConfig.map((action, index) => (
            <Button
              key={index}
              target="_blank"
              variant={action.variant}
              href={action?.href}
              size="sm"
              className="px-1 py-0 "
              onClick={() => action.onClick && action.onClick()}
            >
              <action.Icon size={10} />
              {!action.hideText && action.text && <span className="align-middle mx-1">{action.text}</span>}
            </Button>
          ))}
        </div>
        <div className="d-block d-lg-none">
          <Dropdown>
            <Dropdown.Toggle className="no-caret" variant="transparent" size="sm" aria-label="More actions">
              <ThreeDotsVertical />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {!fromCustomerPortal && (
                <Dropdown.Item href={`${process.env.PUBLIC_URL}/customer?customerId=${activeCustomer?._id}`}>
                  <PersonBoundingBox size={12} />
                  <span className="align-middle mx-2">{translate('view_as_client')}</span>
                </Dropdown.Item>
              )}
              {onUploadFileChange && !readOnlyMode && (
                <Dropdown.Item as="div">
                  <FileUploadButton
                    className="p-0"
                    render={Dropdown.Item}
                    showText
                    forceShowProgress={isFileUploadingToCustomer}
                    onUploadFileChange={onUploadFileChange}
                  />
                </Dropdown.Item>
              )}
              {actionsConfig.map((action, index) => (
                <Dropdown.Item key={index} onClick={action.onClick}>
                  <action.Icon size={12} />
                  {action.text && <span className="align-middle mx-2">{action.text}</span>}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const ActiveCustomerActions = ({
  showRujumBoard,
  onCompTableClick,
  onCompareStrategyClick,
  onReapplyCompClick,
  allowedCalculators = [],
  translate,
  langCode,
  readOnlyMode,
  onTabChange,
  activeTab
}) => {
  const actionsConfig = [];

  if (showRujumBoard) {
    actionsConfig.push({
      onClick: onCompareStrategyClick,
      Icon: GraphUpArrow,
      text: translate('compare_strategies'),
      variant: 'outline-success'
    });
  }

  actionsConfig.push({
    onClick: onCompTableClick,
    Icon: FileSpreadsheet,
    text: translate('comp_table'),
    variant: 'outline-primary'
  });

  const calculators = [
    { name: 'irr_calculator', key: IRR_CALCULATOR_KEY },
    { name: 'pinui_binui', key: PINUI_BINUI_CALCULATOR_KEY },
    { name: 'paper_apartment', key: PAPER_CALCULATOR_KEY },
    { name: 'rsu_options', key: RSU_OPTIONS_KEY }
  ].filter(action => allowedCalculators.includes(action.key));

  return (
    <div
      className={`d-flex justify-content-between align-items-center text-${isRtl(langCode) ? 'start' : 'end'} w-100`}
    >
      <div className="d-flex align-items-center gap-1">
        {showRujumBoard && (
          <Button
            size="sm"
            className="py-0 m-0"
            variant={activeTab === 'rujum' ? 'dark' : 'outline-dark'}
            onClick={() => onTabChange('rujum')}
          >
            {translate('rujum')}
          </Button>
        )}
        {calculators.length > 0 &&
          calculators.map(({ name, key }) => (
            <Button
              size="sm"
              className="py-0 m-0"
              variant={activeTab === key ? 'dark' : 'outline-dark'}
              key={name}
              onClick={() => onTabChange(key)}
            >
              {translate(name)}
            </Button>
          ))}
      </div>
      <div className={`d-none d-lg-block text-${isRtl(langCode) ? 'start' : 'end'}`}>
        {actionsConfig.map((action, index) =>
          action.isLink
            ? action.link && (
                <a target="_blank" href={action.link}>
                  <Button key={index} variant={action.variant} size="sm" className="px-1 py-0 mx-1">
                    <action.Icon size={10} />
                    {action.text && <span className="align-middle mx-1">{action.text}</span>}
                  </Button>
                </a>
              )
            : action.onClick && (
                <Button
                  key={index}
                  variant={action.variant}
                  size="sm"
                  className="px-1 py-0 mx-1"
                  onClick={action.onClick}
                >
                  <action.Icon size={10} />
                  {action.text && <span className="align-middle mx-1">{action.text}</span>}
                </Button>
              )
        )}

        {onReapplyCompClick && !readOnlyMode && (
          <Button variant="outline-primary" size="sm" className="px-1 py-0" onClick={onReapplyCompClick}>
            <ArrowCounterclockwise size={10} />
          </Button>
        )}
      </div>

      <div className={`d-block d-lg-none text-${isRtl(langCode) ? 'start' : 'end'}`}>
        <Dropdown>
          <Dropdown.Toggle variant="transparent" size="sm" aria-label="More actions">
            <span className="mid"> {translate('actions')}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {calculators.length > 0 && (
              <>
                <Dropdown.Header>{translate('calculators')}</Dropdown.Header>
                {calculators.map(({ name, onClick }) => (
                  <Dropdown.Item key={name} onClick={onClick}>
                    {translate(name)}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
              </>
            )}

            {actionsConfig.map(
              (action, index) =>
                action.onClick && (
                  <Dropdown.Item key={index} onClick={action.onClick}>
                    <action.Icon size={12} />
                    {action.text && <span className="align-middle mx-2">{action.text}</span>}
                  </Dropdown.Item>
                )
            )}

            {onReapplyCompClick && !readOnlyMode && (
              <Dropdown.Item onClick={onReapplyCompClick}>
                <FileSpreadsheet size={12} />
                <span className="align-middle mx-2">{translate('reapply')}</span>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const statusToBG = {
  Active: 'success',
  Past: 'danger',
  New: 'dark',
  'Interested In My Course': 'primary'
};

const IrrCalculatorSidebar = ({
  customer,

  onCustomerUpdate,
  translate,
  adminCompTable,
  readOnlyMode,
  assigneUsers
}) => {
  return (
    <div title={`${translate('irr_calculator')} (${customer?.name})`}>
      <RealEstateIRR
        adminCompTable={adminCompTable}
        customer={customer}
        onCustomerUpdate={onCustomerUpdate}
        readOnlyMode={readOnlyMode}
        assigneUsers={assigneUsers}
      />
    </div>
  );
};

const PinuiBinuiIrrCalculatorSidebar = ({
  customer,
  onCustomerUpdate,
  translate,
  adminCompTable,
  readOnlyMode,
  assigneUsers
}) => (
  <div title={`${translate('pinui_binui')} (${customer?.name})`}>
    <PinuiBinuiIRR
      adminCompTable={adminCompTable}
      customer={customer}
      onCustomerUpdate={onCustomerUpdate}
      readOnlyMode={readOnlyMode}
      assigneUsers={assigneUsers}
    />
  </div>
);

const PaperApartmentCalculatorSidebar = ({
  customer,

  onCustomerUpdate,
  translate,
  adminCompTable,
  readOnlyMode,
  assigneUsers
}) => (
  <div title={`${translate('paper_apartment')} (${customer?.name})`}>
    <PaperApartmentIRR
      adminCompTable={adminCompTable}
      customer={customer}
      onCustomerUpdate={onCustomerUpdate}
      readOnlyMode={readOnlyMode}
      assigneUsers={assigneUsers}
    />
  </div>
);

const RSUOptionsCalculatorSidebar = ({
  customer,

  onCustomerUpdate,
  translate,
  adminCompTable,
  readOnlyMode,
  assigneUsers
}) => (
  <div title={`${translate('rsu_options')} (${customer?.name})`}>
    <RSUOptions
      adminCompTable={adminCompTable}
      customer={customer}
      onCustomerUpdate={onCustomerUpdate}
      readOnlyMode={readOnlyMode}
      assigneUsers={assigneUsers}
    />
  </div>
);

const CustomerCard = ({
  onViewCRMContactClick,
  isExampleUser,
  parentCustomer,
  activeCustomer,
  onActiveCustomerSwitched,
  adminCompTable,
  onCompTableClick,
  onEditClick,
  onUpdateStrategyTimeClick,
  onDeleteClick,
  onArchiveClick,
  onCustomerGeneralInfoClick,
  onViewAppointmentClick,
  onCompareStrategyClick,
  onCollapseIconClick,
  showCollapseIcon,
  isSidebarCollapsed,
  onReapplyCompClick,
  onCustomerUpdate,
  onSelectedStrategyChange,
  onShareClick,
  fromCustomerPortal,
  onUploadFileChange,
  onLogsClick,
  onUndoClick,
  onRedoClick,
  isFileUploadingToCustomer,
  onViewFilesClick,
  makePhoneCall,
  customerUpdating,
  onAddProfileClick,
  assigneUsers
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const [activeTab, setActiveTab] = useState(null);
  const [objectFormLoadedFromUrl, setObjectFormLoadedFromUrl] = useState();

  const readOnlyMode = useMemo(() => Boolean(isExampleUser) || !activeCustomer?.editable, [
    activeCustomer,
    isExampleUser
  ]);

  useEffect(() => {
    const allowedTabsForThisCustomer = [...(activeCustomer?.allowedCalculators || [])];
    if (activeCustomer.showRujumBoard) allowedTabsForThisCustomer.unshift('rujum');

    if (!allowedTabsForThisCustomer.includes(activeTab)) {
      setActiveTab(() => allowedTabsForThisCustomer[0]);
    }
  }, [activeCustomer]);

  const fetchCompleteDetails = async () => {
    onCustomerUpdate({ ...parentCustomer, loadingError: false });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_LIST,
      requestBody: {
        filter: {
          _id: parentCustomer._id
        }
      }
    });

    if (error) {
      onCustomerUpdate({ ...parentCustomer, loadingError: true });
      return;
    }

    const customer = response[0];
    if (customer) {
      onCustomerUpdate(customer);
    }
  };

  useEffect(() => {
    //auto details fetching is done only once for customer.
    //if there is an error, customer clicks on reload button, which calls fetchCompleteDetails
    if (!['strategies', 'loadingError'].some(k => parentCustomer.hasOwnProperty(k))) fetchCompleteDetails();
  }, [parentCustomer]);

  if (!parentCustomer.strategies) {
    return (
      <Card className="mb-5">
        <Card.Header>
          <div className="d-flex align-items-center">
            <div className="d-inline-block">
              {showCollapseIcon && (
                <>
                  {isRtl(langCode) ? (
                    isSidebarCollapsed ? (
                      <ArrowLeftCircleFill
                        className="hover-light text-primary rounded-circle"
                        onClick={onCollapseIconClick}
                        size={24}
                      ></ArrowLeftCircleFill>
                    ) : (
                      <ArrowRightCircleFill
                        className="hover-light text-primary rounded-circle"
                        size={24}
                        onClick={onCollapseIconClick}
                      ></ArrowRightCircleFill>
                    )
                  ) : !isSidebarCollapsed ? (
                    <ArrowLeftCircleFill
                      className="hover-light text-primary rounded-circle"
                      onClick={onCollapseIconClick}
                      size={24}
                    ></ArrowLeftCircleFill>
                  ) : (
                    <ArrowRightCircleFill
                      className="hover-light text-primary rounded-circle"
                      size={24}
                      onClick={onCollapseIconClick}
                    ></ArrowRightCircleFill>
                  )}
                </>
              )}
            </div>
            <div className="d-inline-block mx-2">
              <h6 className="mid mb-0">
                {parentCustomer['boardName'] || parentCustomer['name'] || translate('no_name')}
              </h6>
              <h6 className="smallFont text-muted mb-0">{parentCustomer['email'] || translate('no_email')}</h6>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-5 text-center">
          {parentCustomer?.loadingError ? (
            <>
              <ExclamationTriangle className="text-danger" size={24} />
              <UnderlineButton
                fontSize="smallFont"
                className="my-2"
                onClick={fetchCompleteDetails}
                variant="dark"
                text={translate('something_went_wrong_try_again')}
              />
            </>
          ) : (
            <CircularProgressBar />
          )}
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ marginBottom: 125 }}>
      <Card.Header>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 d-flex align-items-center">
            <div className="d-inline-block">
              {showCollapseIcon && (
                <>
                  {isRtl(langCode) ? (
                    isSidebarCollapsed ? (
                      <ArrowLeftCircleFill
                        className="hover-light text-primary rounded-circle"
                        onClick={onCollapseIconClick}
                        size={24}
                      ></ArrowLeftCircleFill>
                    ) : (
                      <ArrowRightCircleFill
                        className="hover-light text-primary rounded-circle"
                        size={24}
                        onClick={onCollapseIconClick}
                      ></ArrowRightCircleFill>
                    )
                  ) : !isSidebarCollapsed ? (
                    <ArrowLeftCircleFill
                      className="hover-light text-primary rounded-circle"
                      onClick={onCollapseIconClick}
                      size={24}
                    ></ArrowLeftCircleFill>
                  ) : (
                    <ArrowRightCircleFill
                      className="hover-light text-primary rounded-circle"
                      size={24}
                      onClick={onCollapseIconClick}
                    ></ArrowRightCircleFill>
                  )}
                </>
              )}
            </div>
            <div className="d-inline-block mx-2">
              <h6 className="mid mb-0">
                {parentCustomer['boardName'] || parentCustomer['name'] || translate('no_name')}
                {!fromCustomerPortal && (
                  <>
                    <Badge bg={statusToBG[parentCustomer['status']]} className="mx-1">
                      {translate(parentCustomer['status'].toLowerCase())}
                    </Badge>
                    {parentCustomer['archived'] && (
                      <Badge className="mx-1" bg={'secondary-dark'}>
                        {translate('archived')}
                      </Badge>
                    )}
                    {parentCustomer['isPublic'] && (
                      <Badge className="mx-1" bg={'dark'}>
                        <a
                          className="text-white text-decoration-none d-flex align-items-center"
                          href={`${process.env.REACT_APP_APP_URL}/example/${parentCustomer?._id}`}
                          target="_blank"
                        >
                          <span className="tiny">{translate('example_open')}</span> <Link size={12} className="mx-1" />
                        </a>
                      </Badge>
                    )}
                  </>
                )}
              </h6>
              <h6 className="smallFont text-muted mb-0">{parentCustomer['email'] || translate('no_email')}</h6>
            </div>
            {!fromCustomerPortal && !readOnlyMode && (
              <UndoRedoButton
                className="mx-4"
                onUndoClick={onUndoClick}
                onRedoClick={onRedoClick}
                disabled={customerUpdating}
              />
            )}
          </div>
          <div>
            <ParentCustomerActions
              translate={translate}
              activeCustomer={activeCustomer}
              isArchived={parentCustomer['archived']}
              onEditClick={onEditClick}
              onViewCRMContactClick={onViewCRMContactClick}
              onDeleteClick={onDeleteClick}
              onArchiveClick={onArchiveClick}
              onCustomerGeneralInfoClick={onCustomerGeneralInfoClick}
              onShareClick={onShareClick}
              onViewFilesClick={onViewFilesClick}
              onUploadFileChange={onUploadFileChange}
              onLogsClick={onLogsClick}
              isFileUploadingToCustomer={isFileUploadingToCustomer}
              readOnlyMode={readOnlyMode}
              fromCustomerPortal={fromCustomerPortal}
              onViewAppointmentClick={onViewAppointmentClick}
              makePhoneCall={makePhoneCall}
              onAddProfileClick={onAddProfileClick}
            />
          </div>
        </div>
        {onAddProfileClick && (
          <div className="d-md-none ">
            <hr className="my-1" />
            <div className="d-flex py-1 justify-content-end">
              {activeCustomer?.apartmentProfiles?.map((apartmentProfile, index) => {
                return (
                  <Button
                    size="sm"
                    className="d-flex justify-content-center align-items-center mx-1"
                    style={{ width: 80 }}
                    variant="outline-dark"
                    onClick={() => onAddProfileClick({ mode: 'update', data: apartmentProfile })}
                  >
                    <h6 className="smallFont mb-0 truncate">
                      {index + 1}. {apartmentProfile?.profileName}
                    </h6>
                  </Button>
                );
              })}
              <Button
                size="sm"
                className="d-flex align-items-center mx-0 px-1"
                variant="outline-dark"
                onClick={() => onAddProfileClick({ mode: 'add', data: {} })}
              >
                <Building />
                <Plus />
              </Button>
            </div>
          </div>
        )}
      </Card.Header>
      <Card.Body className="">
        <div className="d-flex align-items-center">
          {parentCustomer['accessibleUsers'].length > 0 && (
            <div className={`flex-grow-1 mb-2`}>
              <div>
                <b className="mx-1 smallFont">{translate('viewing_user')}:</b>
                <DropdownSelector
                  options={[parentCustomer, ...parentCustomer['accessibleUsers'].filter(u => Boolean(u))].map(user => ({
                    label: user.name || translate('no_name'),
                    value: user._id
                  }))}
                  value={activeCustomer._id}
                  onValueChange={customerId =>
                    onActiveCustomerSwitched(
                      [parentCustomer, ...parentCustomer['accessibleUsers'].filter(u => Boolean(u))].find(
                        c => c._id === customerId
                      )
                    )
                  }
                />
              </div>
            </div>
          )}

          <ActiveCustomerActions
            translate={translate}
            langCode={langCode}
            onCompTableClick={onCompTableClick}
            showRujumBoard={activeCustomer?.showRujumBoard}
            allowedCalculators={activeCustomer['allowedCalculators']}
            onCompareStrategyClick={onCompareStrategyClick}
            onReapplyCompClick={onReapplyCompClick}
            readOnlyMode={readOnlyMode}
            onTabChange={setActiveTab}
            activeTab={activeTab}
          />
        </div>
        <hr />
        {activeCustomer.showRujumBoard && activeCustomer?.strategies && activeTab === 'rujum' && (
          <>
            <CustomerStrategies
              adminCompTable={adminCompTable}
              activeCustomer={activeCustomer}
              onCustomerUpdate={onCustomerUpdate}
              fromCustomerPortal={fromCustomerPortal}
              onSelectedStrategyChange={onSelectedStrategyChange}
              onUpdateStrategyTimeClick={onUpdateStrategyTimeClick}
              readOnlyMode={readOnlyMode}
              objectFormLoadedFromUrl={objectFormLoadedFromUrl}
              setObjectFormLoadedFromUrl={setObjectFormLoadedFromUrl}
            />
          </>
        )}
        {[
          {
            key: IRR_CALCULATOR_KEY,
            Calculator: IrrCalculatorSidebar
          },
          {
            key: PINUI_BINUI_CALCULATOR_KEY,
            Calculator: PinuiBinuiIrrCalculatorSidebar
          },
          {
            key: PAPER_CALCULATOR_KEY,
            Calculator: PaperApartmentCalculatorSidebar
          },
          {
            key: RSU_OPTIONS_KEY,
            Calculator: RSUOptionsCalculatorSidebar
          }
        ]
          .filter(a => activeCustomer['allowedCalculators']?.includes(a.key))
          .map(
            ({ Calculator, key }) =>
              activeTab === key && (
                <Calculator
                  translate={translate}
                  customer={activeCustomer}
                  adminCompTable={adminCompTable}
                  onCustomerUpdate={onCustomerUpdate}
                  readOnlyMode={readOnlyMode}
                  assigneUsers={assigneUsers}
                />
              )
          )}
      </Card.Body>
    </Card>
  );
};

export default CustomerCard;
