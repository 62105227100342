import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { Check, Pen, X } from 'react-bootstrap-icons';
import CircularProgressBar from './circular-progress';

const TextWithEdit = ({ text, onSubmit, preFix = '', editable, updateInProgress }) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(text);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!updateInProgress) setEditMode(false);
  }, [updateInProgress]);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const onValueSubmit = async () => {
    setDisabled(true);
    const valid = onSubmit && (await onSubmit(value));
    setDisabled(false);

    if (valid) setEditMode(false);
  };

  return editMode ? (
    <div className="d-flex align-items-center">
      {preFix}
      <div className="flex-grow-2 smallFont">
        <FormControl
          autoComplete="off"
          autoFocus
          size={'sm'}
          disabled={disabled}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </div>

      <div className="d-flex align-items-center">
        {!updateInProgress ? (
          <>
            <Check
              className={`ml-2 ${disabled ? 'text-muted' : 'text-success hover-light'}`}
              size={16}
              onClick={() => {
                if (!disabled) onValueSubmit();
              }}
            />
            <X
              className={`ml-2 ${disabled ? 'text-muted' : 'text-danger hover-light'}`}
              size={16}
              onClick={() => {
                if (!disabled) setEditMode(false);
              }}
            />
          </>
        ) : (
          <span className={'ms-1'} style={{ maxWidth: 16, maxHeight: 16 }}>
            <CircularProgressBar size={1} />
          </span>
        )}
      </div>
    </div>
  ) : (
    <div
      className={editable ? 'hover-light' : ''}
      onClick={() => {
        if (editable && !disabled) setEditMode(true);
      }}
    >
      {preFix}
      {text}
    </div>
  );
};

export default TextWithEdit;
