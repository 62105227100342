import { uniqueId } from 'lodash';
import {
  calculateSellingTaxForOnlyAppartment,
  calculateTaxFromBrackets,
  formatCurrency,
  formatPercentage,
  getNumberFromFormattedPercentage,
  getNumberFromFormattedValue
} from '../admin/manage-users/customer/strategies/helper';

export const getInitialValues = (allFields, compTable, includeKeyAndFile = true, preFix = '') => {
  const allValues = allFields.reduce(
    (prevObj, newField) => ({
      ...prevObj,
      [`${preFix}${newField.objectKey}`]:
        prevObj[`${preFix}${newField.objectKey}`] ||
        ((newField.defaultValue || newField.defaultValueCompSymbol) && !newField.type
          ? formatCurrency(
              newField.defaultValue ?? getVariableFromCompTable(compTable, newField.defaultValueCompSymbol),
              false
            )
          : newField.defaultValue ?? (getVariableFromCompTable(compTable, newField.defaultValueCompSymbol) || ''))
    }),
    {}
  );

  return includeKeyAndFile ? { ...allValues, id: uniqueId(), files: [] } : allValues;
};

export const getVariableFromCompTable = (compTable, variableSymbol) => {
  return compTable?.variables?.find(v => v.symbol === variableSymbol)?.value || 0;
};

export const computeTaxesForCalculators = (
  sellingPrice,
  assetValue,
  onlyApartmentWhileBuying,
  onlyApartmentWhileSelling,
  compTable,
  additionalCostsInvolved = 0
) => {
  const {
    buyingTaxBrackets,
    sellingTaxBrackets,
    buyingTaxBracketsForOnlyApartment,
    sellingTaxBracketsForOnlyApartment
  } = compTable;

  const onlyApartment = new Object();
  if (onlyApartmentWhileBuying) onlyApartment.buying = true;
  if (onlyApartmentWhileSelling) onlyApartment.selling = true;

  //calculate buying tax
  const buyingTax = calculateTaxFromBrackets(
    assetValue,
    onlyApartment?.buying ? buyingTaxBracketsForOnlyApartment : buyingTaxBrackets
  );

  const totalBuyingTax = buyingTax.reduce((total, t) => total + t.actualTax, 0);

  const pnl = sellingPrice - assetValue - additionalCostsInvolved - totalBuyingTax;

  const sellingTaxes = onlyApartment?.selling
    ? calculateSellingTaxForOnlyAppartment(sellingPrice, pnl, sellingTaxBracketsForOnlyApartment)
    : calculateTaxFromBrackets(pnl, onlyApartment?.selling ? sellingTaxBracketsForOnlyApartment : sellingTaxBrackets);

  const sellingTax = sellingTaxes.reduce((total, t) => total + t.actualTax, 0);

  return { buyingTax: totalBuyingTax, sellingTax };
};

export const getPercentageValue = (allFields, objectKey, value) => {
  //if field is an input, we do not need to multiply by 100 because it is already done in input
  if (allFields.some(f => f.objectKey === objectKey && f.isInput)) return value ? Number(value).toFixed(2) : 0;
  return value ? (value * 100).toFixed(2) : 0;
};

export const getParsedValues = (allFields, passedValues, adminCompTable, percentageInDecimalForm = true) => {
  const parsedValues = {};
  parsedValues.multiplier = getVariableFromCompTable(adminCompTable, 'A13');
  parsedValues.vat = getVariableFromCompTable(adminCompTable, 'A23') / (percentageInDecimalForm ? 100 : 1);
  parsedValues.compTable = adminCompTable;
  parsedValues.downPayment =
    (getNumberFromFormattedPercentage(passedValues?.downPayment) || 0) * (percentageInDecimalForm ? 0.01 : 1);

  allFields
    .filter(f => f.isInput)
    .map(f => f.objectKey)
    .forEach(key => {
      const type = allFields.find(({ objectKey }) => key === objectKey)?.type;
      let value = passedValues[key];

      switch (type) {
        case 'switch':
          parsedValues[key] = value || null;
          break;
        case 'block-select':
          parsedValues[key] = value;
          break;
        case 'boolean':
          parsedValues[key] = Boolean(value);
          break;
        case 'percentage':
          parsedValues[key] = !value
            ? 0
            : Number(value.toString().replace(/[^.\d]/g, '')) / (percentageInDecimalForm ? 100 : 1);
          break;
        case 'rate':
        case 'dropdown':
          parsedValues[key] = value || '';
          break;
        case 'paperApartmentPayments':
          parsedValues[key] =
            value?.map(v => {
              const month = v.month ? Number(v.month) : 0;
              const value = v.value ? Number(v.value.toString().replace(/[^.\d]/g, '')) : 0;

              return { ...v, month: !isNaN(month) ? month : 0, value: !isNaN(value) ? value : 0 };
            }) || [];
          break;
        case 'text':
          parsedValues[key] = value || '';
          break;
        case 'range':
          parsedValues[key] = value ?? null;
          break;
        default:
          parsedValues[key] = !value ? 0 : Number(value.toString().replace(/[^.\d]/g, ''));
          break;
      }
    });

  return parsedValues;
};

export const getRSUOptionsParsedValues = (allFields, sections, adminCompTable, passedValues) => {
  const parsedValues = {};
  parsedValues.compTable = adminCompTable;

  allFields
    .filter(f => f.isInput)
    .flatMap(f => sections.map(s => `${s.id}${f.objectKey}`))
    .forEach(key => {
      const value = passedValues[key] || 0;

      const type = allFields.find(({ objectKey }) => sections.map(s => `${s.id}${objectKey}`).includes(key))?.type;

      switch (type) {
        case 'boolean':
        case 'dropdown':
          parsedValues[key] = value;
          break;
        case 'percentage':
          parsedValues[key] = Number(value.toString().replace(/[^.\d]/g, '')) / 100;
          break;
        default:
          parsedValues[key] = Number(value.toString().replace(/[^.\d.-]/g, ''));
          break;
      }
    });

  return parsedValues;
};

export const updateApartmentValues = (allFields, apartment, objectKey, currentInputType, newValue, otherInfo = {}) => {
  if (currentInputType === 'dependent-percentage') {
    const percentageValue = getNumberFromFormattedPercentage(newValue);
    const dependentValue = getNumberFromFormattedValue(apartment[otherInfo.percentageOfField]);

    apartment[objectKey] =
      percentageValue && dependentValue ? formatCurrency(((percentageValue * dependentValue) / 12) * 0.01) : '';
    apartment[`${objectKey}-percentage`] = newValue;
  } else {
    apartment[objectKey] = !currentInputType && newValue ? formatCurrency(newValue) : newValue;
    if (otherInfo.percentageOfField) {
      const dependentValue = getNumberFromFormattedValue(apartment[otherInfo.percentageOfField]);
      const valueOfThisField = getNumberFromFormattedValue(newValue);

      apartment[`${objectKey}-percentage`] =
        valueOfThisField && dependentValue
          ? formatPercentage((valueOfThisField * 12 * 100) / dependentValue, false, true)
          : '';
    }
    //find if this field is percentageOfField of any other field
    const dependentOfs = allFields.filter(f => f.percentageOfField === objectKey);
    dependentOfs.forEach(dependentOf => {
      const field = dependentOf.objectKey;
      const percentageValue = getNumberFromFormattedPercentage(apartment[`${field}-percentage`]);
      const dependentValue = getNumberFromFormattedValue(newValue);

      apartment[field] =
        percentageValue && dependentValue ? formatCurrency(((percentageValue * dependentValue) / 12) * 0.01) : '';
    });
  }
};

export const getPercentageValues = (app, allIrrFields = []) => {
  if (!app) return;

  const newFieldsToReturn = {};

  const fieldsWithPercentageFields = allIrrFields.filter(f => f.percentageOfField);
  fieldsWithPercentageFields.forEach(field => {
    if (app[field.objectKey]) {
      const dependentValue = getNumberFromFormattedValue(app[field.percentageOfField]);
      const valueOfThisField = getNumberFromFormattedValue(app[field.objectKey]);

      newFieldsToReturn[`${field.objectKey}-percentage`] =
        valueOfThisField && dependentValue
          ? formatPercentage((valueOfThisField * 12 * 100) / dependentValue, false, true)
          : '';
    }
  });

  return newFieldsToReturn;
};

export const getInitialPaperApartmentPayments = a => {
  const apartmentValue = a.inputPrice ? getNumberFromFormattedValue(a.inputPrice) : 0;
  if (!a.paperApartmentPayments) return [];

  return a.paperApartmentPayments.map(a => {
    const paymentValue = a.value ? getNumberFromFormattedValue(a.value) : 0;
    let perc = apartmentValue === 0 ? 0 : (paymentValue * 100) / apartmentValue;
    perc = !Number.isInteger(perc) ? parseFloat(perc.toFixed(2)) : perc;

    return { ...a, id: uniqueId(), '%': perc };
  });
};

export const updatePaperApartmentPaymentsFromPerc = a => {
  const apartmentValue = a.inputPrice ? getNumberFromFormattedValue(a.inputPrice) : 0;
  if (!a.paperApartmentPayments) return [];

  return a.paperApartmentPayments.map(a => {
    const percentageValue = a['%'] ? getNumberFromFormattedPercentage(a['%']) : 0;
    const value = percentageValue * apartmentValue * 0.01;
    return { ...a, value };
  });
};

export const getRemainingSuggestedPaperApartmentPayments = ({
  paperApartmentPayments: existingPaperApartmentPayments = [],
  remainingPayment,
  inputPrice = 0,
  downPayment = 0,
  estimatedTimeForFinishingContruction
}) => {
  inputPrice = getNumberFromFormattedValue(inputPrice) || 0;

  let remainingPayments = [];

  existingPaperApartmentPayments.sort((p1, p2) => {
    return p1.month - p2.month;
  });

  let downPaymentAmount = (getNumberFromFormattedPercentage(downPayment) || 0) * 0.01 * inputPrice || 0; //Adding down payment.

  let remainingAmountToPay =
    inputPrice -
    downPaymentAmount -
    existingPaperApartmentPayments.reduce((value, p) => getNumberFromFormattedPercentage(p.value) + value, 0);

  //when there are paper payments, gradual payments would start after the final Payment
  const farthestPaymentMonth =
    existingPaperApartmentPayments.length > 0 ? Math.max(...existingPaperApartmentPayments.map(p => p.month)) - 1 : 0;

  const installmentTimePeriodInMonths = estimatedTimeForFinishingContruction - (farthestPaymentMonth || 0);

  if (installmentTimePeriodInMonths === 0 && remainingAmountToPay > 0) {
    //no time left but still some value to pay, so we pay at the last month
    remainingPayments.push({
      id: uniqueId(),
      value: remainingAmountToPay,
      '%': (remainingAmountToPay * 100) / inputPrice,
      month: farthestPaymentMonth + 1,
      isSystemGenerated: true
    });
    return remainingPayments;
  }

  const numberOfInstallments = remainingPayment === 'oneFinalPayment' ? 1 : installmentTimePeriodInMonths / 12;

  const baseInstallment = remainingAmountToPay / numberOfInstallments;

  const numberOfYearsInEachLoop = installmentTimePeriodInMonths / 12 / numberOfInstallments;
  const doesFinalInstallmentEndBeforeYearEnd = numberOfInstallments % 1 !== 0;

  for (let i = 0; i < numberOfInstallments; i++) {
    const isInstallmentNotAtTheEndOfYear =
      doesFinalInstallmentEndBeforeYearEnd && i === Math.floor(numberOfInstallments);

    const remainingMonthsIfNotAtEndOfYear = installmentTimePeriodInMonths % 12;

    const installmentToConsider = isInstallmentNotAtTheEndOfYear
      ? (numberOfInstallments % 1) * baseInstallment
      : baseInstallment;

    const yearsPassed =
      (farthestPaymentMonth + 1 || 0) / 12 + numberOfYearsInEachLoop * (i + (isInstallmentNotAtTheEndOfYear ? 0 : 1));
    const monthsPassed = isInstallmentNotAtTheEndOfYear ? remainingMonthsIfNotAtEndOfYear : 0;

    remainingPayments.push({
      id: uniqueId(),
      value: installmentToConsider,
      '%': (installmentToConsider * 100) / inputPrice,
      month: Math.round(yearsPassed * 12 + monthsPassed),
      isSystemGenerated: true
    });
  }

  return remainingPayments.filter(p => p.value > 0);
};

export const getDownpaymentAmount = a => {
  return (
    (getNumberFromFormattedPercentage(a?.downPayment) || 0) * 0.01 * (getNumberFromFormattedValue(a.inputPrice) || 0)
  );
};
