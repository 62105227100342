import { isValidMongoId } from './global';

const keysToCompare = [
  { key: 'assets', compareBy: 'name' },
  { key: 'liabilities', compareBy: 'name' },
  { key: 'fixedIncomeAssets', compareBy: 'name' },
  { key: 'oneOffChanges', compareBy: 'description' },
  { key: 'customCashflows', compareBy: 'name' },
  { key: 'investments', compareBy: 'groupId' }
];

export const compareStrategies = (comparisonStrategies = [], keysToIgnore = []) => {
  const compareArrays = (parentArray, compareArrays, compareBy) => {
    const getIdentifier = item => item?.[compareBy] || item?._id; // Fallback to _id if property is missing

    const parentSet = new Map(parentArray.map(item => [getIdentifier(item), item]));
    const compareSets = compareArrays.map(
      compareArray => new Map(compareArray.map(item => [getIdentifier(item), item]))
    );

    // Unique set of all asset/liability identifiers across strategies
    const allKeys = new Set([...parentSet.keys(), ...compareSets.flatMap(set => [...set.keys()])]);

    return [...allKeys]
      .map(identifier => {
        const comparisons = [
          {
            result: '-',
            object: parentSet.get(identifier)
          },
          ...compareSets.map((compareSet, compareStrategyIndex) => {
            const object = compareSet.get(identifier);
            const parentObject = parentSet.get(identifier);

            if (!object) {
              return !parentObject ? { result: '-' } : { result: 'Deleted' };
            }
            if (!parentObject) return { result: 'Added', object };

            return findChangedKeys(
              parentObject,
              object,
              comparisonStrategies[0],
              comparisonStrategies[compareStrategyIndex + 1],
              keysToIgnore
            ).length === 0
              ? { result: '-', object }
              : { result: 'Updated', object };
          })
        ];

        const hasChanges = comparisons.some(c => c.result !== '-');
        if (!hasChanges) return null;

        return {
          identifier,
          comparisons
        };
      })
      .filter(Boolean);
  };

  const comparisonResult = {};

  keysToCompare.forEach(({ key, compareBy }) => {
    comparisonResult[key] = compareArrays(
      comparisonStrategies[0]?.[key] || [],
      comparisonStrategies.slice(1).map(s => s[key] || []),
      compareBy
    );
  });

  return comparisonResult;
};

export const findDifferencesInObjects = (objects = [], strategies = [], ignoreFields = [], doNotIncludeFields = []) => {
  if (!objects || objects.length < 2) return [];

  const o1 = objects[0]; // Reference object
  const result = [[]];

  for (let i = 1; i < objects.length; i++) {
    const o2 = objects[i];
    const changedKeys = findChangedKeys(o1, o2, strategies[0], strategies[i], ignoreFields, doNotIncludeFields);
    result.push(changedKeys);
  }

  return result;
};

const findChangedKeys = (o1, o2, strategy1, strategy2, ignoreFields = [], doNotIncludeFields = []) => {
  const changedKeys = [];

  if (!o1) o1 = {};
  if (!o2) o2 = {};

  Object.keys(o1).forEach(k => {
    if (ignoreFields.includes(k)) return;
    if (doNotIncludeFields.includes(k)) return;
    if (!o2.hasOwnProperty(k)) return;

    if (Array.isArray(o1[k]) && Array.isArray(o2[k])) {
      if (
        o1[k].length !== o2[k].length ||
        !o1[k].every((e, index) => {
          if (typeof e === 'object') {
            return (
              Object.keys(findChangedKeys(e, o2[k][index], strategy1, strategy2, ignoreFields, doNotIncludeFields))
                .length === 0
            );
          }
          return e === o2[k][index];
        })
      ) {
        changedKeys.push(k);
      }
      return;
    }

    if (typeof o1[k] === 'object' && typeof o2[k] === 'object') {
      const changeInChildKeys = findChangedKeys(o1[k], o2[k], strategy1, strategy2, ignoreFields, doNotIncludeFields);
      if (changeInChildKeys.length > 0) {
        changedKeys.push(k);
      }
      return;
    }

    const valueToCompare = o2[k];
    if (typeof valueToCompare === 'number' && !o1[k]) {
      if (0 !== valueToCompare) changedKeys.push(k);
      return;
    }

    if (isValidMongoId(o1[k]) || isValidMongoId(o2[k])) {
      //replace id with object in one of the arrays
      const name1 = replaceIdWithName(strategy1, o1[k]);
      const name2 = replaceIdWithName(strategy2, o2[k]);
      if (name1 !== name2) changedKeys.push(k);
      return;
    }

    if (o1[k] !== valueToCompare) changedKeys.push(k);
  });

  return changedKeys;
};

export const findAllKeysInObjects = (objects = [], keysToIgnore = []) => {
  const uniqueKeys = new Set();

  objects.forEach(obj => {
    if (!obj) return;
    Object.keys(obj).forEach(key => uniqueKeys.add(key));
  });

  return Array.from(uniqueKeys)
    .filter(k => !keysToIgnore.includes(k))
    .sort();
};

export const replaceIdWithName = (strategy = {}, id) => {
  for (const { key } of keysToCompare) {
    const array = strategy[key] || [];
    const name = array.find(a => a._id === id)?.name;
    if (name) return name;
  }

  return;
};
