import { snakeCase } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, FormCheck, Overlay, Popover, Table } from 'react-bootstrap';
import {
  ArrowDownRight,
  ArrowReturnLeft,
  ArrowUpRight,
  ArrowsAngleContract,
  ArrowsAngleExpand,
  ArrowsExpand,
  Check,
  CircleFill,
  Files,
  Pen,
  PlusCircle,
  Trash,
  X
} from 'react-bootstrap-icons';
import { assetCategoriesOptions, monthSmall, primaryBankAccountName } from '../../../../../helpers/constants';
import useLocalization from '../../../../../hooks/useLocalization';
import LTRNumberCell from '../../../../common/LTRNumberCell';
import TextWithEdit from '../../../../common/TextWithEdit';
import UnderlineButton from '../../../../common/UnderlineButton';
import {
  findYearlyRateOfCompouding,
  formatCurrency,
  formatPercentage,
  getFinalYearOfStrategy,
  getStrategyYears,
  getTransactionsAtMonthYear,
  getValueAtMonthYear,
  isPrimaryBankAccount,
  isRSUAsset,
  sortInstruments
} from './helper';
import Summary from '../../../../calculators/IrrBreakdownSummary';
import AlertModal from '../../../../common/AlertModal';

const hasMonthlyValues = cashflowItem => {
  return cashflowItem.yearlyValues?.some(y => y.monthlyValues?.some(m => m !== 0));
};

const checkIfExpanded = (expandedYears, year) => expandedYears.includes(year);

const getKey = (...uniqueTerms) => uniqueTerms.join('-');

const getAnnualAppreciation = strategy => {
  const startYear = strategy.initialYear;
  const startMonth = strategy.initialMonth || 0;

  const endYear = getFinalYearOfStrategy(strategy);

  const assetsStartTotal = strategy['assets']
    .filter(
      a =>
        isPrimaryBankAccount(a) ||
        (!a.isInvalid && !a.toBeMerged && !a.editMode && a.buyingMonth === startMonth && a.buyingYear === startYear)
    )
    .reduce((prevValue, a) => prevValue + a.value, 0);

  const liabilityStartTotal = strategy['liabilities']
    .filter(
      a => !a.isInvalid && !a.toBeMerged && !a.editMode && a.startMonth === startMonth && a.startYear === startYear
    )
    .reduce((prevValue, a) => prevValue + a.value, 0);

  const assetsEndTotal = strategy['assets']
    .filter(a => !a.isInvalid && !a.toBeMerged)
    .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, 11, endYear), 0);

  const liabilityEndTotal = strategy['liabilities']
    .filter(a => !a.isInvalid && !a.toBeMerged)
    .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, 11, endYear), 0);

  const valueAtStart = assetsStartTotal - liabilityStartTotal;
  const valueAtEnd = assetsEndTotal - liabilityEndTotal;

  if (valueAtEnd - valueAtStart === 0) return 0;

  return findYearlyRateOfCompouding(valueAtEnd, valueAtStart, strategy.totalYears ?? 10);
};

//hack to hide popover
const hidePopover = () => document.body.click();

export const ActionCell = ({
  disabled,
  onEditClick,
  onDeleteClick,
  onAddEventClick,
  onDuplicateClick,
  onDisableClick,
  isItemNotApplied
}) => (
  <div style={{ display: 'flex' }}>
    {onDeleteClick && (
      <Button
        disabled={disabled}
        variant="outline-danger"
        className="px-1 py-0 me-1"
        onClick={() => {
          onDeleteClick();
          hidePopover();
        }}
      >
        <Trash size={10} />
      </Button>
    )}
    {onAddEventClick && (
      <Button
        disabled={disabled}
        variant="outline-dark"
        className="px-1 py-0 me-1"
        onClick={() => {
          onAddEventClick();
          hidePopover();
        }}
      >
        <PlusCircle size={10} />
      </Button>
    )}
    {onDuplicateClick && (
      <Button
        disabled={disabled}
        variant="outline-dark"
        className="px-1 py-0 me-1"
        onClick={() => {
          onDuplicateClick();
          hidePopover();
        }}
      >
        <Files size={10} />
      </Button>
    )}
    {onEditClick && (
      <Button
        disabled={disabled}
        variant="outline-dark"
        className="px-1 py-0 me-1"
        onClick={() => {
          onEditClick();
          hidePopover();
        }}
      >
        <Pen size={10} />
      </Button>
    )}

    {onDisableClick && (
      <Button
        disabled={disabled}
        variant={isItemNotApplied ? 'danger' : 'success'}
        className="px-1 py-0 me-1 text-white"
        onClick={() => {
          onDisableClick();
          hidePopover();
        }}
      >
        {isItemNotApplied ? <X size={12} /> : <Check size={12} />}
      </Button>
    )}
  </div>
);

const getStartAndEndMonthIndexOfAnYear = ({ year, initialMonth, initialYear, lastYear }) => {
  const startEndIndex =
    initialMonth === 0 || (year !== lastYear && year !== initialYear)
      ? { start: 0, end: 12 }
      : { start: year === lastYear ? 0 : initialMonth, end: year === lastYear ? initialMonth : 12 };

  return startEndIndex;
};

const TableHeaderRenderer = ({
  year,
  isExpanded,
  handleExpandYear,
  handleCollapseMonths,
  initialMonth = 0,
  initialYear,
  lastYear,
  currentMonth,
  currentYear,
  isCurrentYearExpanded,
  expandedYears,
  expandedMonthYears,
  ...props
}) => {
  const monthsToConsider = useMemo(() => {
    const startEndIndex = getStartAndEndMonthIndexOfAnYear({
      year,
      initialMonth,
      initialYear,
      lastYear
    });

    // For the current year
    if (currentYear === year) {
      if (isCurrentYearExpanded) {
        // Show all months if expanded
        return monthSmall.slice(startEndIndex.start, startEndIndex.end);
      } else {
        const startAndEndToShow = expandedMonthYears.filter(i => i.year === year);
        return startAndEndToShow.map(i => monthSmall[i.month]);
      }
    }

    // For other years
    if (expandedYears?.includes(year)) {
      return monthSmall.slice(startEndIndex.start, startEndIndex.end); // Expanded view
    }

    return [monthSmall[0]]; // Default view: only January
  }, [year, initialMonth, initialYear, lastYear, currentYear, currentMonth, isCurrentYearExpanded, expandedYears]);

  if (currentYear === year && !isCurrentYearExpanded) {
    return monthsToConsider.map((monthName, index) => (
      <th
        className={`${monthName === monthSmall[currentMonth] ? 'masked-cell' : ''}`}
        style={{ minWidth: 110 }}
        key={`${year}-${monthName}`}
        {...props}
        data-month={monthName}
      >
        {monthName} {year}
        <ArrowsAngleExpand
          size={10}
          onClick={() => handleExpandYear(year)}
          style={{ cursor: 'pointer', marginRight: '2px' }}
        />
      </th>
    ));
  }
  if (!isExpanded) {
    return (
      <th
        className={`${currentYear === year && currentMonth === 0 ? 'masked-cell' : ''}`}
        style={{ minWidth: 110 }}
        key={`${year}-${monthSmall[0]}`}
        {...props}
        data-month={monthSmall[0]}
      >
        {monthsToConsider[0]} {year}
        <ArrowsAngleExpand
          size={10}
          onClick={() => handleExpandYear(year)}
          style={{ cursor: 'pointer', marginRight: '2px' }}
        />
      </th>
    );
  }

  return monthsToConsider.map((month, index) => (
    <th
      className={`bg-danger-light bg-opacity-25 ${index === currentMonth ? 'masked-cell' : ''}`}
      style={{ minWidth: 110 }}
      key={year + month}
      {...props}
      data-month={month}
    >
      {month} {year}
      <ArrowsAngleContract
        size={10}
        onClick={() => handleCollapseMonths(year)}
        style={{ cursor: 'pointer', marginRight: '2px' }}
      />
    </th>
  ));
};

const CashFlowSection = ({
  disabled,
  expandedYears,
  expandedMonthYears,
  strategyYears,
  editable,
  strategy,
  handleExpandYear,
  handleCollapseMonths,
  onEditClick,
  onDeleteClick,
  onAddCashflowClick,
  translate,
  onNameUpdateSubmit,
  isCurrentYearExpanded
}) => {
  const [expandedCashflows, setExpandedCashflows] = useState([]);
  const [hideRowsWithNoValue, setHideRowsWithNoValue] = useState(true);

  const expandibleCashflows = useMemo(
    () =>
      !strategy.cashflows ? [] : [...new Set(strategy.cashflows.filter(i => i.toBeMerged).map(i => i.parentName))],
    [strategy]
  );

  const cashflowsToShow = useMemo(
    () =>
      !strategy.cashflows
        ? []
        : strategy.cashflows.filter(i => !i.toBeMerged || expandedCashflows.includes(i.parentName)),
    [strategy, expandedCashflows]
  );

  const onExpandCollapseClick = name => {
    if (expandedCashflows.includes(name)) {
      expandedCashflows.splice(expandedCashflows.indexOf(name), 1);
    } else {
      expandedCashflows.push(name);
    }
    setExpandedCashflows([...expandedCashflows]);
  };

  const incomeItems = useMemo(
    () =>
      sortInstruments(
        cashflowsToShow.filter(item => item.type === 'inflow' && (!hideRowsWithNoValue || hasMonthlyValues(item)))
      ),
    [cashflowsToShow, hideRowsWithNoValue]
  );

  const expenseItems = useMemo(
    () =>
      sortInstruments(
        cashflowsToShow.filter(item => item.type === 'outflow' && (!hideRowsWithNoValue || hasMonthlyValues(item)))
      ),
    [cashflowsToShow, hideRowsWithNoValue]
  );

  const tableRef = useRef(null);
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const currentMonth = useMemo(() => new Date().getMonth(), []);
  const [alreadyScrolled, setAlreadyScrolled] = useState(false);

  useEffect(() => {
    if (alreadyScrolled) return;
    if (!tableRef.current) return;

    setTimeout(() => {
      const toBeScrolledToCell = tableRef.current.querySelector(
        `[data-month="${monthSmall[currentMonth]}"][data-year="${currentYear}"]`
      );
      if (toBeScrolledToCell) {
        const parentContainer = tableRef.current.parentElement; // Get scrollable container
        if (parentContainer) {
          parentContainer.scrollLeft = toBeScrolledToCell.offsetLeft - 140;
        }
        setAlreadyScrolled(true);
      }
    }, 200); // Delay to ensure rendering is complete
  }, [expandedYears]);

  return (
    <React.Fragment>
      <div id="cashflowSection" className="d-flex mt-4 align-items-center">
        <div className="flex-grow-1">
          <h6>
            <b>{translate('cashflow')}</b>
            {editable && (
              <UnderlineButton
                text={translate('add_cashflow')}
                Icon={PlusCircle}
                fontSize="mid"
                variant="success"
                className="mx-1"
                disabled={disabled}
                onClick={onAddCashflowClick}
              />
            )}
          </h6>
        </div>
        <div className="d-none d-md-block">
          <FormCheck
            size={'sm'}
            label={<b className="text-dark mid">{translate('hide_zero_rows')}</b>}
            checked={hideRowsWithNoValue}
            onChange={e => setHideRowsWithNoValue(e.target.checked)}
          />
        </div>
      </div>
      <div className="d-flex d-md-none mb-1">
        <FormCheck
          size={'sm'}
          label={<b className="text-dark mid">{translate('hide_zero_rows')}</b>}
          checked={hideRowsWithNoValue}
          onChange={e => setHideRowsWithNoValue(e.target.checked)}
        />
      </div>

      <Table ref={tableRef} responsive bordered className="smallFont mb-2">
        <thead>
          <tr className="bg-dark text-white">
            <th style={{ minWidth: 120 }}></th>
            {strategyYears.map(year => (
              <TableHeaderRenderer
                key={year}
                year={year}
                lastYear={strategyYears[strategyYears.length - 1]}
                initialMonth={strategy.initialMonth}
                initialYear={strategy.initialYear}
                isExpanded={expandedYears.includes(year)}
                handleExpandYear={handleExpandYear}
                handleCollapseMonths={handleCollapseMonths}
                data-year={year}
                currentYear={currentYear}
                currentMonth={currentMonth}
                expandedMonthYears={expandedMonthYears}
                isCurrentYearExpanded={isCurrentYearExpanded}
                expandedYears={expandedYears}
              />
            ))}
            {editable && <th style={{ minWidth: 80 }}>{translate('actions')}</th>}
          </tr>
        </thead>
        <tbody>
          {[
            { name: 'income', items: incomeItems, noDataText: 'no_incomes', totalText: 'total_income' },
            { name: 'expenditure', items: expenseItems, noDataText: 'no_expenditure', totalText: 'total_expenditure' }
          ].map(({ name, items, noDataText, totalText }) => (
            <React.Fragment key={name}>
              <tr className="bg-primary text-white">
                <td className="p-1">
                  <b>{translate(name)}</b>
                </td>
                <td colSpan={expandedMonthYears.length} className="p-1 bg-primary"></td>
                {editable && <td></td>}
              </tr>
              {items.length > 0 ? (
                items.map((item, index) => (
                  <tr
                    key={item._id || index}
                    className={
                      item.parentName && expandedCashflows.includes(item.parentName)
                        ? 'bg-secondary'
                        : `bg-${item.isInvalid ? 'danger-light' : 'white'}`
                    }
                  >
                    <td>
                      {expandibleCashflows.includes(item.name) && (
                        <ArrowsExpand className="mx-1  hover-light" onClick={() => onExpandCollapseClick(item.name)} />
                      )}
                      <TextWithEdit
                        editable={editable}
                        text={item.name}
                        onSubmit={value => onNameUpdateSubmit('customCashflows', item, value)}
                      />
                    </td>
                    {expandedMonthYears.map(({ month, year }) => (
                      <BalanceCell
                        key={getKey(item._id, month, year)}
                        value={getValueAtMonthYear(item, month, year)}
                        disabled={disabled}
                        onEditClick={editable && onEditClick && (() => onEditClick(item))}
                        onDeleteClick={editable && item._id && onDeleteClick && (() => onDeleteClick(item))}
                        month={month}
                        year={year}
                        irr={item.irr}
                        irrCashflowBreakdown={item.irrCashflowBreakdown}
                      />
                    ))}
                    {editable && (
                      <td>
                        {!item.toBeMerged && (
                          <ActionCell
                            disabled={disabled}
                            onEditClick={!item.isAnonaPayment && (() => onEditClick(item))}
                            onDeleteClick={(item._id || item.isReinvestment) && (() => onDeleteClick(item))}
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={13}>{translate(noDataText)}</td>
                </tr>
              )}
              <tr className="bg-primary-light">
                <td>
                  <b>{translate(totalText)}</b>
                </td>
                {expandedMonthYears.map(({ month, year }) => (
                  <BalanceCell
                    key={`${year}-${month}`}
                    value={items
                      .filter(a => !a.isInvalid && !a.toBeMerged)
                      .reduce((acc, cashflow) => {
                        acc = acc + getValueAtMonthYear(cashflow, month, year);
                        return acc;
                      }, 0)}
                    month={month}
                    year={year}
                  />
                ))}
                {editable && <td style={{ minWidth: 80 }}></td>}
              </tr>
            </React.Fragment>
          ))}
          {/** NET */}
          <tr className="bg-dark text-white">
            <td>
              <strong>{translate('net')}</strong>
            </td>
            {expandedMonthYears.map(({ month, year }) => (
              <BalanceCell
                key={`${year}-${month}`}
                value={
                  incomeItems
                    .filter(a => !a.isInvalid && !a.toBeMerged)
                    .reduce((acc, cashflow) => acc + getValueAtMonthYear(cashflow, month, year), 0) -
                  expenseItems
                    .filter(a => !a.isInvalid && !a.toBeMerged)
                    .reduce((acc, cashflow) => acc + getValueAtMonthYear(cashflow, month, year), 0)
                }
                month={month}
                year={year}
                //
              />
            ))}
            {editable && <td style={{ minWidth: 80 }}></td>}
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
};

const typeToColor = {
  Add: 'success',
  Subtract: 'danger',
  Set: 'primary',
  Default: 'primary'
};

const typeToIcon = {
  Add: <ArrowUpRight size={8} className={`text-${typeToColor['Add']}`} />,
  Subtract: <ArrowDownRight size={8} className={`text-${typeToColor['Subtract']}`} />,
  Set: <ArrowReturnLeft size={8} className={`text-${typeToColor['Set']}`} />,
  Default: <CircleFill size={8} className={`text-${typeToColor['Default']}`} />
};

const TransactionRow = ({ transaction }) => {
  const { type, value, label } = transaction;
  return (
    <div className="d-flex tiny hover-light">
      <div>{typeToIcon[type || 'Default']}</div>
      <div className={`mx-1 flex-grow-1`}>
        <b className="text-muted">{label}</b>
      </div>
      <div className={`text-${typeToColor[type || 'Default']}`}>
        <b>
          {type === 'Subtract' ? '-' : ''}
          {formatCurrency(value)}
        </b>
      </div>
    </div>
  );
};

const AppreciatedBalance = ({ transaction, translate }) => {
  const appreciation = Math.round(transaction?.appreciation || 0);

  return (
    <div className="mb-1">
      <b>{translate('opening_balance')}: </b>
      {formatCurrency(transaction?.value)}
      <b
        className={`mx-1 tiny bg-${
          appreciation === 0 ? 'primary' : appreciation > 0 ? 'success' : 'danger'
        } px-1 rounded text-white`}
      >
        {appreciation.toFixed(2)}%
      </b>
    </div>
  );
};

const ValueCell = ({ bold, value }) =>
  bold ? (
    <b>
      <LTRNumberCell value={value} />
    </b>
  ) : (
    <LTRNumberCell value={value} />
  );

const mergeTransactions = (transactions = [], translate) => {
  //if same loan starts and ends in this month, do not show it in transactions
  let transactionsWithId = transactions
    /*  .filter(transaction => {
      if (
        transaction.transactionType === 'loanStart' &&
        transactions.findIndex(
          t => t.transactionType === 'loanEnd' && t.liability._id === transaction.liability._id
        ) !== -1
      ) {
        return false;
      }

      if (
        transaction.transactionType === 'loanEnd' &&
        transactions.findIndex(
          t => t.transactionType === 'loanStart' && t.liability._id === transaction.liability._id
        ) !== -1
      ) {
        return false;
      }

      return true;
    }) */
    .map((t, index) => ({ ...t, index }));

  //buyingTax, purchaseCost
  const allBuyAssetTransactions = transactionsWithId.filter(t => t.transactionType === 'assetBuy');
  //buyingTax, purchaseCost
  const allSellAssetTransactions = transactionsWithId.filter(t => t.transactionType === 'assetSell');

  //liability
  const allStartLoanTransactions = transactionsWithId.filter(
    t => t.transactionType === 'loanStart' && !t.isToClearInstallment
  );
  const allEndLoanTransactions = transactionsWithId.filter(t => t.transactionType === 'loanEnd');

  const allStartLoanForDebtTransactions = transactionsWithId.filter(
    t => t.transactionType === 'loanStart' && t.liability.isToClearInstallment
  );

  //liability
  const allInstallmentForDebtTransactions = transactionsWithId.filter(
    t => t.transactionType === 'payInstallment' && t.debtFor
  );

  const newTransactions = [];

  //combine buy and start loan into equity
  allBuyAssetTransactions.forEach(transaction => {
    //reduce costs from value
    transaction.value =
      transaction.value -
      (transaction.purchaseCost || 0) -
      (transaction.realtorCost || 0) -
      (transaction.renovationCost || 0) -
      (transaction.buyingTax || 0);

    //if it is real estate, combine loan and asset transaction to equity
    if (transaction.asset?.category === 'Real Estate') {
      const linkedTransactions = allStartLoanTransactions.filter(
        ({ liability }) =>
          !liability.isInvalid &&
          (liability.debtFor === transaction.asset.name ||
            (liability.relatedAssets && liability.relatedAssets.includes(transaction.asset.name)))
      );

      if (linkedTransactions.length) {
        let mergedTransaction = { ...transaction };
        const loanValue = linkedTransactions.reduce((acc, t) => acc + t.value, 0);
        mergedTransaction.value = mergedTransaction.value - loanValue;

        newTransactions.push(mergedTransaction);

        //remove all transactions that have been merged
        transactionsWithId = transactionsWithId.filter(
          ({ index }) => ![...linkedTransactions, transaction].map(t => t.index).includes(index)
        );
      }
    }

    // purchase cost transaction
    if (transaction.purchaseCost) {
      newTransactions.push({
        ...transaction,
        value: transaction.purchaseCost,
        label: `${translate('additional_purchase_cost_for')} ${transaction.asset.name}`
      });
    }
    // tax transaction
    if (transaction.buyingTax) {
      newTransactions.push({
        ...transaction,
        value: transaction.buyingTax,
        label: `${translate('buying_tax_for')} ${transaction.asset.name}`
      });
    }
    // realtor transaction
    if (transaction.realtorCost) {
      newTransactions.push({
        ...transaction,
        value: transaction.realtorCost,
        label: `${translate('realtor_cost_for')} ${transaction.asset.name}`
      });
    }
    // renovation cost
    if (transaction.renovationCost) {
      newTransactions.push({
        ...transaction,
        value: transaction.renovationCost,
        label: `${translate('renovation_cost_for')} ${transaction.asset.name}`
      });
    }
  });

  //combine sell and end loan into equity
  allSellAssetTransactions.forEach(transaction => {
    //reduce costs from value
    transaction.value = transaction.value + (transaction.salesCost || 0) + (transaction.sellingTax || 0);

    //if it is real estate, combine loan and asset transaction to equity
    if (transaction.asset?.category === 'Real Estate') {
      const linkedTransactions = allEndLoanTransactions.filter(
        ({ liability }) =>
          !liability.isInvalid &&
          (liability.debtFor === transaction.asset.name ||
            (liability.relatedAssets && liability.relatedAssets.includes(transaction.asset.name)))
      );

      if (linkedTransactions.length) {
        let mergedTransaction = { ...transaction };
        const loanValue = linkedTransactions.reduce((acc, t) => acc + t.value, 0);
        mergedTransaction.value = mergedTransaction.value - loanValue;

        newTransactions.push(mergedTransaction);

        //remove all transactions that have been merged
        transactionsWithId = transactionsWithId.filter(
          ({ index }) => ![...linkedTransactions, transaction].map(t => t.index).includes(index)
        );
      }
    }

    // sales cost transaction
    if (transaction.salesCost) {
      newTransactions.push({
        ...transaction,
        type: 'Subtract',
        value: transaction.salesCost,
        label: `Additional Sales Cost for ${transaction.asset.name}`
      });
    }
    // tax transaction
    if (transaction.sellingTax) {
      newTransactions.push({
        ...transaction,
        type: 'Subtract',
        value: transaction.sellingTax,
        label: `Selling Tax for ${transaction.asset.name}`
      });
    }
  });

  //combine pay debt and debt loan transaction
  allStartLoanForDebtTransactions.forEach(transaction => {
    const liability = transaction.liability;
    const linkedInstallmentTransactions = allInstallmentForDebtTransactions.filter(({ debtFor }) =>
      liability.relatedAssets.includes(debtFor)
    );

    if (linkedInstallmentTransactions.length) {
      let mergedTransaction = { ...transaction };
      const debtPaid = linkedInstallmentTransactions.reduce((acc, t) => acc + t.value, 0);

      const equityUsed = mergedTransaction.value - debtPaid;
      mergedTransaction.value = Math.abs(equityUsed);
      mergedTransaction.label = linkedInstallmentTransactions[0].label;
      mergedTransaction.index = linkedInstallmentTransactions[0].index;
      mergedTransaction.type = equityUsed > 0 ? 'Add' : 'Subtract';
      newTransactions.push(mergedTransaction);

      //remove all transactions that have been merged
      transactionsWithId = transactionsWithId.filter(
        ({ index }) => ![...linkedInstallmentTransactions, transaction].map(t => t.index).includes(index)
      );
    }
  });

  return [...transactionsWithId.filter(t => t.type !== 'opening_balance'), ...newTransactions].sort(
    (a, b) => a.index - b.index
  );
};

const BalanceCell = ({
  value,
  hideActionCell,
  bold = false,
  transactions,
  month,
  year,
  onEditClick,
  onDeleteClick,
  onAddEventClick,
  onDuplicateAssetLiabilityClick,
  disabled,
  irr,
  irrCashflowBreakdown
}) => {
  const { translate, langCode, isRTL } = useLocalization();
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const mergedTransactions = useMemo(() => transactions && mergeTransactions(transactions, translate), [
    transactions,
    langCode
  ]);

  const hasIrr = useMemo(() => irr !== null && irr !== undefined, [irr]);
  const isCurrentMonthAndYear = useMemo(() => month === new Date().getMonth() && year === new Date().getFullYear(), [
    month,
    year
  ]);

  return (
    <>
      <td
        className={`${Math.round(value) < 0 ? 'bg-danger-light bg-opacity-25' : ''} ${
          isCurrentMonthAndYear ? 'masked-cell' : ''
        }`}
        style={{ minWidth: 110 }}
        ref={target}
        onClick={() => {
          if (window.getSelection().toString()) return;

          setShow(!show);
        }}
      >
        <span className="hover-light">
          <ValueCell value={Math.round(value)} bold={bold} />
        </span>
      </td>
      <Overlay target={target.current} show={show} placement="bottom" onHide={() => setShow(false)} rootClose>
        <Popover style={{ width: 300 }} id="popover-basic">
          <Popover.Header className={`px-1 ${mergedTransactions ? '' : 'bg-white rounded'}`}>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h6 className="mb-0 smallFont">
                  {mergedTransactions ? (
                    <>
                      {translate('transactions')}{' '}
                      <b>
                        ({monthSmall[month]} {year})
                      </b>
                    </>
                  ) : (
                    <b>
                      {monthSmall[month]} {year}
                    </b>
                  )}
                </h6>
              </div>
              {!hideActionCell && (
                <ActionCell
                  disabled={disabled}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                  onAddEventClick={onAddEventClick}
                  onDuplicateClick={onDuplicateAssetLiabilityClick}
                />
              )}
            </div>
          </Popover.Header>
          {(hasIrr || mergedTransactions) && (
            <Popover.Body className="p-1 smallFont">
              {mergeTransactions && (
                <>
                  <AppreciatedBalance
                    translate={translate}
                    transaction={transactions.find(t => t.type === 'opening_balance')}
                  />
                  {mergedTransactions.map((t, index) => (
                    <TransactionRow key={index} transaction={t} />
                  ))}
                </>
              )}
              {irr !== null && irr !== undefined && (
                <>
                  <hr className="mt-2 mb-1" />
                  <h6 className={`px-1 mb-0 smallFont text-${isRTL ? 'start' : 'end'}`}>
                    <b>{translate('irr')}: </b> <span>{formatPercentage(irr, false, true)} </span>{' '}
                    {irrCashflowBreakdown && <Summary data={irrCashflowBreakdown} extraInfo={{ timeLabel: 'month' }} />}
                  </h6>
                </>
              )}
            </Popover.Body>
          )}
        </Popover>
      </Overlay>
    </>
  );
};

const BalanceSheetSection = ({
  expandedMonthYears = [],
  disabled,
  onDuplicateAssetLiabilityClick,
  section,
  editable,
  strategy,
  onEditClick,
  onDeleteClick,
  onAddEventClick,
  translate,
  onNameSubmit,
  quickRenaming
}) => {
  const [expandedInstruments, setExpandedInstruments] = useState([]);
  const expandibleInstruments = useMemo(
    () => [...new Set(strategy[section.key].filter(i => i.toBeMerged).map(i => i.parentId))],
    [section, strategy]
  );

  const instrumentsToShow = useMemo(
    () => strategy[section.key].filter(i => !i.toBeMerged || expandedInstruments.includes(i.parentId)),
    [section, strategy, expandedInstruments]
  );

  const onExpandCollapseClick = a => {
    const id = a._id || a.id;
    if (expandedInstruments.includes(id)) {
      expandedInstruments.splice(expandedInstruments.indexOf(id), 1);
    } else {
      expandedInstruments.push(id);
    }
    setExpandedInstruments([...expandedInstruments]);
  };

  return (
    <>
      <tr id={`${section.key}Section`} className="bg-primary text-white">
        <td className="p-1">
          <b>{section.title}</b>
        </td>
        <td className="p-1 bg-primary" colSpan={expandedMonthYears.length}></td>
        <td className="p-1">
          <b>{translate(section.key === 'assets' ? 'appreciation' : 'interest')}</b>
        </td>
        {editable && <td></td>}
      </tr>
      {instrumentsToShow.length > 0 ? (
        section.key === 'assets' ? (
          assetCategoriesOptions.map(assetCategory => {
            const assetsOfThisCategory = instrumentsToShow.filter(a =>
              a.categoryToShowIn ? a.categoryToShowIn === assetCategory : a.category === assetCategory
            );

            return assetsOfThisCategory.length > 0 ? (
              <React.Fragment key={assetCategory}>
                {/**  Asset Category Name */}
                <tr className="bg-primary-light">
                  <td>
                    <b>{translate(snakeCase(assetCategory)) || assetCategory}</b>
                  </td>
                  <td colSpan={expandedMonthYears.length + 1 + (editable ? 1 : 0)} className="bg-primary-light"></td>
                </tr>
                {/** All ITEMS OF THIS CATEGORY */}
                {sortInstruments(assetsOfThisCategory).map(a => (
                  <tr
                    key={a._id}
                    className={
                      a.parentId && expandedInstruments.includes(a.parentId)
                        ? 'bg-secondary'
                        : `bg-${a.isInvalid ? 'danger-light' : 'white'}`
                    }
                  >
                    <td>
                      {expandibleInstruments.includes(a._id || a.id) && (
                        <ArrowsExpand className="mx-1 hover-light" onClick={() => onExpandCollapseClick(a)} />
                      )}
                      <TextWithEdit
                        editable={editable && !isPrimaryBankAccount(a)}
                        text={isPrimaryBankAccount(a) ? translate('primary_bank_account') : a.name}
                        onSubmit={value => onNameSubmit(section.key, a, value)}
                        updateInProgress={quickRenaming}
                      />
                      {a.category === 'RSU' && (a.isVestedRSU ? '(Vested)' : '(Unvested)')}
                    </td>
                    {expandedMonthYears.map(({ month, year }) => (
                      <BalanceCell
                        disabled={disabled}
                        onDuplicateAssetLiabilityClick={
                          onDuplicateAssetLiabilityClick && (() => onDuplicateAssetLiabilityClick(a))
                        }
                        onEditClick={onEditClick && (() => onEditClick(a))}
                        onDeleteClick={onDeleteClick && !isPrimaryBankAccount(a) && (() => onDeleteClick(a))}
                        onAddEventClick={onAddEventClick && !isRSUAsset(a) && (() => onAddEventClick(a, month, year))}
                        key={getKey(a._id, month, year)}
                        hideActionCell={a.toBeMerged || (!a._id && !a.derivedFrom)}
                        value={getValueAtMonthYear(a, month, year)}
                        transactions={getTransactionsAtMonthYear(a, month, year)}
                        month={month}
                        year={year}
                        irr={a.irr}
                        irrCashflowBreakdown={a.irrCashflowBreakdown}
                      />
                    ))}
                    <td>{(a.returnAppreciation || a.interest || 0).toFixed(2)}%</td>
                    {editable && (
                      <td>
                        {!(a.toBeMerged || (!a._id && !a.derivedFrom)) && (
                          <ActionCell
                            disabled={disabled}
                            onEditClick={() => onEditClick(a)}
                            onDeleteClick={!isPrimaryBankAccount(a) && (() => onDeleteClick(a))}
                            onAddEventClick={!isRSUAsset(a) && (() => onAddEventClick(a))}
                            onDuplicateClick={
                              onDuplicateAssetLiabilityClick && (() => onDuplicateAssetLiabilityClick(a))
                            }
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
                {/** TOTAL OF THIS CATEGORY */}
                <tr className="bg-secondary">
                  <td>
                    <b>{translate('total')}</b>
                  </td>
                  {expandedMonthYears.map(({ month, year }) => {
                    const value = assetsOfThisCategory
                      .filter(a => !a.isInvalid && !a.toBeMerged)
                      .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, month, year), 0);
                    return (
                      <BalanceCell
                        key={getKey(assetCategory, month, year)}
                        value={value}
                        bold
                        month={month}
                        year={year}
                      />
                    );
                  })}

                  <td></td>
                  {editable && <td></td>}
                </tr>
              </React.Fragment>
            ) : (
              <></>
            );
          })
        ) : (
          sortInstruments(instrumentsToShow).map(a => (
            <tr
              key={a._id}
              className={
                a.parentId && expandedInstruments.includes(a.parentId)
                  ? 'bg-secondary'
                  : `bg-${a.isInvalid ? 'danger-light' : 'white'}`
              }
            >
              <td>
                {expandibleInstruments.includes(a._id || a.id) && (
                  <ArrowsExpand className="mx-1 hover-light" onClick={() => onExpandCollapseClick(a)} />
                )}
                <TextWithEdit
                  editable={editable}
                  text={a.name}
                  onSubmit={value => onNameSubmit(section.key, a, value)}
                  updateInProgress={quickRenaming}
                />
              </td>
              {expandedMonthYears.map(({ month, year }) => (
                <BalanceCell
                  disabled={disabled}
                  onDuplicateAssetLiabilityClick={
                    onDuplicateAssetLiabilityClick &&
                    !a.toBeMerged &&
                    a._id &&
                    (() => onDuplicateAssetLiabilityClick(a))
                  }
                  onEditClick={onEditClick && (() => onEditClick(a))}
                  onDeleteClick={onDeleteClick && a.name !== primaryBankAccountName && (() => onDeleteClick(a))}
                  onAddEventClick={onAddEventClick && (() => onAddEventClick(a, month, year))}
                  key={getKey(a._id, month, year)}
                  value={getValueAtMonthYear(a, month, year)}
                  transactions={getTransactionsAtMonthYear(a, month, year)}
                  month={month}
                  year={year}
                  hideActionCell={a.toBeMerged || (!a._id && !a.derivedFrom)}
                  irr={a.irr}
                  irrCashflowBreakdown={a.irrCashflowBreakdown}
                />
              ))}
              <td>{(a.interest || 0).toFixed(2)}%</td>
              {editable && (
                <td>
                  {!(a.toBeMerged || (!a._id && !a.derivedFrom)) && (
                    <ActionCell
                      disabled={disabled}
                      onEditClick={() => onEditClick(a)}
                      onDeleteClick={() => onDeleteClick(a)}
                      onAddEventClick={() => onAddEventClick(a)}
                      onDuplicateClick={() => onDuplicateAssetLiabilityClick(a)}
                    />
                  )}
                </td>
              )}
            </tr>
          ))
        )
      ) : (
        <tr>
          <td colSpan={13}>No {section.title}</td>
        </tr>
      )}
      {/* TOTAL OF THIS ASSETS/LIABILITIES */}
      <tr className="bg-primary-light">
        <td>
          <b>{translate(`total_${section.key}`)}</b>
        </td>
        {expandedMonthYears.map(({ month, year }) => {
          const value = instrumentsToShow
            .filter(a => !a.isInvalid && !a.toBeMerged)
            .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, month, year), 0);

          return <BalanceCell key={getKey('total', month, year)} value={value} month={month} year={year} bold />;
        })}
        <td></td>
        {editable && <td></td>}
      </tr>
    </>
  );
};

const BalanceSheetView = ({
  strategy,
  strategyYears,
  disabled,
  editable,
  onDuplicateAssetLiabilityClick,
  onEditClick,
  onDeleteClick,
  expandedYears,
  expandedMonthYears,
  isCurrentYearExpanded,
  handleExpandYear,
  handleCollapseMonths,
  onAddEventClick,
  translate,
  currentLanguage,
  onNameUpdateSubmit,
  quickRenaming
}) => {
  const sections = useMemo(
    () => [
      { title: translate('assets'), key: 'assets' },
      { title: translate('liabilities'), key: 'liabilities' }
    ],
    [currentLanguage]
  );

  const tableRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const [alreadyScrolled, setAlreadyScrolled] = useState(false);

  useEffect(() => {
    if (alreadyScrolled) return;
    if (!tableRef.current) return;

    const toBeScrolledToCell = tableRef.current.querySelector(
      `[data-month="${monthSmall[currentMonth]}"][data-year="${currentYear}"]`
    );

    if (toBeScrolledToCell) {
      setTimeout(() => {
        const parentContainer = tableRef.current.parentElement; // Get scrollable container
        if (parentContainer) {
          parentContainer.scrollLeft = toBeScrolledToCell.offsetLeft - 140;
        }
        setAlreadyScrolled(true);
      }, 100); // Delay to ensure rendering is complete
    }
  }, [expandedYears]);

  return (
    <>
      {strategy['assets'].length > 0 || strategy['liabilities'].length > 0 ? (
        <Table ref={tableRef} responsive bordered className="smallFont mb-2">
          <thead>
            <tr className="bg-dark text-white">
              <th style={{ minWidth: 120 }}></th>
              {strategyYears.map(year => (
                <TableHeaderRenderer
                  key={year}
                  lastYear={strategyYears[strategyYears.length - 1]}
                  initialMonth={strategy.initialMonth}
                  initialYear={strategy.initialYear}
                  year={year}
                  isExpanded={expandedYears.includes(year)}
                  expandedYears={expandedYears}
                  isCurrentYearExpanded={isCurrentYearExpanded}
                  handleExpandYear={handleExpandYear}
                  handleCollapseMonths={handleCollapseMonths}
                  data-year={year}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                  expandedMonthYears={expandedMonthYears}
                />
              ))}
              <th style={{ minWidth: 80 }}></th>
              {editable && <th style={{ minWidth: 80 }}>{translate('actions')}</th>}
            </tr>
          </thead>
          <tbody>
            {sections.map(s => (
              <BalanceSheetSection
                key={s.key}
                editable={editable}
                disabled={disabled}
                onDuplicateAssetLiabilityClick={
                  editable &&
                  (item => {
                    const triggerInstrument = item.derivedFrom
                      ? strategy[s.key].find(i => i._id === item.derivedFrom)
                      : item;
                    onDuplicateAssetLiabilityClick(s.key, triggerInstrument);
                  })
                }
                onEditClick={
                  editable &&
                  (item => {
                    const triggerInstrument = item.derivedFrom
                      ? strategy[s.key].find(i => i._id === item.derivedFrom)
                      : item;
                    onEditClick(s.key, triggerInstrument);
                  })
                }
                onDeleteClick={
                  editable &&
                  (item => {
                    const triggerInstrument = item.derivedFrom
                      ? strategy[s.key].find(i => i._id === item.derivedFrom)
                      : item;
                    onDeleteClick(s.key, triggerInstrument);
                  })
                }
                onAddEventClick={
                  editable &&
                  ((item, month, year) => {
                    const triggerInstrument = item.derivedFrom
                      ? strategy[s.key].find(i => i._id === item.derivedFrom)
                      : item;
                    onAddEventClick(triggerInstrument, s.key === 'assets', month, year);
                  })
                }
                strategy={strategy}
                section={s}
                expandedMonthYears={expandedMonthYears}
                onNameSubmit={onNameUpdateSubmit}
                quickRenaming={quickRenaming}
                translate={translate}
              />
            ))}
            {/** EQUITY */}
            <tr className="bg-dark text-white">
              <td style={{ minWidth: 120 }}>
                <b>{translate('equity')}</b>
              </td>
              {expandedMonthYears.map(({ month, year }) => {
                const assetsTotal = strategy['assets']
                  .filter(a => !a.isInvalid && !a.toBeMerged)
                  .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, month, year), 0);

                const liabilityTotal = strategy['liabilities']
                  .filter(a => !a.isInvalid && !a.toBeMerged)
                  .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, month, year), 0);

                const value = assetsTotal - liabilityTotal;
                // here
                return (
                  <BalanceCell
                    key={getKey('total', month, year)}
                    value={value}
                    bold
                    month={month}
                    year={year}
                    //
                  />
                );
              })}
              <th style={{ minWidth: 80 }}>{translate('annual_appreciation')}</th>
              {editable && (
                <th style={{ minWidth: 80 }}>
                  <LTRNumberCell value={getAnnualAppreciation(strategy)} formatType="percentage" />
                </th>
              )}
            </tr>
          </tbody>
        </Table>
      ) : (
        <h6 className="p-5 text-center">{translate('nothing_to_show')}</h6>
      )}
    </>
  );
};

const StrategyBalanceSheet = ({
  strategy,
  onDuplicateAssetLiabilityClick,
  cashflowEditable,
  assetLiabilityEditable,
  disabled,
  onEditClick,
  onDeleteClick,
  onAddEventClick,
  onEditCashflowClick,
  onDeleteCashflowClick,
  onAddCashflowClick,
  onNameUpdateSubmit,
  quickRenaming
}) => {
  const { translate, langCode } = useLocalization();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [expandedYears, setExpandedYears] = useState([currentYear]);
  const [isCurrentYearFullyExpanded, setIsCurrentYearFullyExpanded] = useState(false);

  // for name update on TextWithEdit
  const [confirmNameEditModalProps, setConfirmNameEditModalProps] = useState({
    show: false,
    item: null,
    section: null,
    newName: null
  });

  const handleNameUpdateSubmit = (section, item, newName) => {
    if (item.name === newName) {
      return onNameUpdateSubmit(section, item, newName);
    }

    setConfirmNameEditModalProps({
      show: true,
      section,
      item,
      newName
    });
  };

  const confirmNameUpdate = () => {
    const { section, item, newName } = confirmNameEditModalProps;
    onNameUpdateSubmit(section, item, newName);
    setConfirmNameEditModalProps({ show: false, item: null, section: null, newName: null });
  };

  const handleExpandYear = year => {
    if (year === currentYear) {
      setIsCurrentYearFullyExpanded(true);
    } else {
      setExpandedYears([...expandedYears, year]);
    }
  };

  const handleCollapseMonths = year => {
    if (year === currentYear) {
      setIsCurrentYearFullyExpanded(false); // Collapse to default view
    } else {
      setExpandedYears(prev => prev.filter(y => y !== year)); // Collapse other years
    }
  };

  const strategyYears = useMemo(() => getStrategyYears(strategy), [strategy]);

  const expandedMonthYears = useMemo(
    () =>
      strategyYears.flatMap(year => {
        const startEndIndex = getStartAndEndMonthIndexOfAnYear({
          year,
          initialMonth: strategy.initialMonth,
          initialYear: strategy.initialYear,
          lastYear: strategyYears[strategyYears.length - 1]
        });
        const months = monthSmall.slice(startEndIndex.start, startEndIndex.end);
        if (year === currentYear && !isCurrentYearFullyExpanded) {
          return Boolean(startEndIndex.start === currentMonth && currentMonth !== 0)
            ? [{ year, month: currentMonth }]
            : currentMonth !== 0
            ? [
                { year, month: startEndIndex.start },
                { year, month: currentMonth }
              ]
            : [{ year, month: startEndIndex.start }];
        }
        return (checkIfExpanded(expandedYears, year) ? months : [months[0]]).map((_, month) => ({
          year,
          month: month + startEndIndex.start
        }));
      }),
    [strategy, expandedYears, strategyYears, isCurrentYearFullyExpanded]
  );

  return (
    <React.Fragment>
      <BalanceSheetView
        strategy={strategy}
        strategyYears={strategyYears}
        editable={assetLiabilityEditable}
        disabled={disabled}
        onDuplicateAssetLiabilityClick={onDuplicateAssetLiabilityClick}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        expandedYears={expandedYears}
        expandedMonthYears={expandedMonthYears}
        handleExpandYear={handleExpandYear}
        handleCollapseMonths={handleCollapseMonths}
        onAddEventClick={onAddEventClick}
        translate={translate}
        currentLanguage={langCode}
        onNameUpdateSubmit={handleNameUpdateSubmit}
        quickRenaming={quickRenaming}
        isCurrentYearExpanded={isCurrentYearFullyExpanded}
      />
      <CashFlowSection
        strategy={strategy}
        strategyYears={strategyYears}
        editable={cashflowEditable}
        disabled={disabled}
        expandedYears={expandedYears}
        expandedMonthYears={expandedMonthYears}
        handleExpandYear={handleExpandYear}
        handleCollapseMonths={handleCollapseMonths}
        onEditClick={onEditCashflowClick}
        onDeleteClick={onDeleteCashflowClick}
        onAddCashflowClick={onAddCashflowClick}
        translate={translate}
        onNameUpdateSubmit={onNameUpdateSubmit}
        isCurrentYearExpanded={isCurrentYearFullyExpanded}
      />

      {confirmNameEditModalProps.show && (
        <AlertModal
          show={confirmNameEditModalProps.show}
          onHide={() => setConfirmNameEditModalProps({ show: false, item: null, section: null, newName: null })}
          alertText={
            confirmNameEditModalProps.section === 'assets'
              ? translate('change_asset_name_confirm')
              : translate('change_liability_name_confirm')
          }
          continueButtonText={translate('yes')}
          onContinueClick={confirmNameUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default StrategyBalanceSheet;
