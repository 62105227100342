import React, { useContext, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { commonChartConfig } from '../../../helpers/constants';
import { LocalizeContext } from 'react-locale-language';
import { useMemo } from 'react';
import { isRtl } from '../../../helpers/localization';
import { Button } from 'react-bootstrap';

const zoomOptions = {
  pan: {
    enabled: true,
    mode: 'xy',
    modifierKey: 'alt',
    onPanStart: function({ chart, point }) {
      const area = chart.chartArea;
      if (point.x < area.left || point.x > area.right || point.y < area.top || point.y > area.bottom) {
        return false; // abort
      }
    }
  },
  zoom: {
    mode: 'xy',
    drag: {
      enabled: true,
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 1,
      backgroundColor: 'rgba(54, 162, 235, 0.3)'
    },
    onZoomStart: function({ chart, point }) {
      const area = chart.chartArea;
      if (point.x < area.left || point.x > area.right || point.y < area.top || point.y > area.bottom) {
        return false; // abort
      }
    }
  }
};

const LineChart = ({
  chartData: originalChartData,
  onMouseHover,
  onMouseExit,
  visibleLines = [],
  onVisibleLinesChange,
  shouldFillBetweenLines,
  hideLegend,
  chartHeight
}) => {
  const chartRef = useRef(null);

  const { langCode, translate } = useContext(LocalizeContext);
  const [chartData, setChartData] = useState({ ...originalChartData });

  useEffect(() => {
    const newChartData = {
      ...originalChartData,
      datasets: [
        {
          label: translate('select_unselect_all'),
          data: [] // no data
        },
        ...originalChartData.datasets.filter((s, index) => !hideLegend || visibleLines[index])
      ]
    };

    if (shouldFillBetweenLines && originalChartData.datasets.length >= 2) {
      const data1 = originalChartData.datasets[0].data;
      const data2 = originalChartData.datasets[1].data;

      // Create two arrays to hold the area data
      const areaDataGreater = new Array(data1.length).fill(null);
      const areaDataLesser = new Array(data1.length).fill(null);

      data1.forEach((income, index) => {
        const incomeValue = Number(income);
        const expenseValue = Number(data2[index]);
        if (incomeValue > expenseValue) {
          // Fill in the areaDataGreater array
          areaDataGreater[index] = incomeValue;
        } else {
          // Fill in the areaDataLesser array
          areaDataLesser[index] = expenseValue;
        }
      });

      const ctx = chartRef.current?.ctx;
      if (ctx) {
        const gradientGreen = ctx.createLinearGradient(0, 0, 0, ctx.canvas.clientHeight);
        gradientGreen.addColorStop(0, 'rgba(30, 200, 0, 0.2)');
        gradientGreen.addColorStop(0.5, 'rgba(30, 200, 0, 0.2)');
        gradientGreen.addColorStop(1, 'transparent');

        const gradientRed = ctx.createLinearGradient(0, 0, 0, ctx.canvas.clientHeight);
        gradientRed.addColorStop(0, 'rgba(255, 0, 0, 0.2)');
        gradientRed.addColorStop(0.5, 'rgba(255, 0, 0, 0.2)');
        gradientRed.addColorStop(1, 'transparent');

        newChartData.datasets.push({
          label: 'Area Greater',
          data: areaDataGreater,
          backgroundColor: gradientGreen,
          borderColor: 'transparent',
          fill: '-1'
        });

        newChartData.datasets.push({
          label: 'Area Lesser',
          data: areaDataLesser,
          backgroundColor: gradientRed,
          borderColor: 'transparent',
          fill: '1'
        });
      }
    }

    setChartData(newChartData);
  }, [shouldFillBetweenLines, originalChartData, translate, visibleLines, hideLegend]);

  function handleResetZoom() {
    const chart = chartRef.current;
    chart.resetZoom();
  }

  const commonConfig = useMemo(() => commonChartConfig(isRtl(langCode)), [langCode]);

  function handleLegendClick(event, legendItem) {
    const chart = chartRef.current;
    if (legendItem.datasetIndex === 0) {
      // assuming the last dataset is the select/unselect all option
      const visible = !chart.data.datasets[0].hidden; // invert visibility of the first dataset
      chart.data.datasets.forEach(dataset => {
        dataset.hidden = visible;
      });
      chart.update();
      onVisibleLinesChange && onVisibleLinesChange(chart.data.datasets.map(dataset => !dataset.hidden));
    } else {
      // default behaviour
      const dataset = chart.data.datasets[legendItem.datasetIndex];
      dataset.hidden = !dataset.hidden;
      chart.update();
      const updatedVisibility = [...visibleLines];
      updatedVisibility[legendItem.datasetIndex - 1] = !updatedVisibility[legendItem.datasetIndex - 1];
      onVisibleLinesChange && onVisibleLinesChange(updatedVisibility);
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between w-100 my-1 px-2">
        <h6 className="fw-bold smallFont text-muted">{translate('press_alt_to_move')}</h6>
        <Button varient="primary" size="sm" className="px-1 py-0" onClick={handleResetZoom}>
          {translate('reset_zoom')}
        </Button>
      </div>
      <div className="chart-container" onMouseLeave={onMouseExit} style={{ height: chartHeight }}>
        <Line
          responsive={true}
          ref={chartRef}
          data={chartData}
          options={{
            ...commonConfig,
            onHover: (event, chartElement) => {
              if (chartElement[0]) {
                onMouseHover && onMouseHover(chartElement[0]);
              }
            },
            plugins: {
              ...commonConfig.plugins,
              legend: hideLegend
                ? false
                : {
                    ...commonConfig.plugins.legend,
                    labels: {
                      ...commonConfig.plugins.legend.labels,
                      // Use a filter function to exclude certain datasets from the legend
                      filter: function(legendItem, data) {
                        // Exclude datasets with specific labels
                        const label = data.datasets[legendItem.datasetIndex].label;
                        return label !== 'Area Greater' && label !== 'Area Lesser';
                      }
                    },

                    onClick: handleLegendClick
                  },
              tooltip: {
                ...commonConfig.plugins.tooltip,
                callbacks: {
                  // Custom label callback to modify tooltip labels
                  label: function(context) {
                    let label = context.dataset.label || '';

                    if (label === 'Income' || label === 'Expense') {
                      const value = context.parsed.y;
                      return `${label}: ${value.toLocaleString()}`;
                    }

                    if (label === 'Area Greater' || label === 'Area Lesser') {
                      // Exclude 'Area Greater' and 'Area Lesser' from the tooltip
                      return null;
                    }

                    // Default label format
                    return `${label}: ${context.parsed.y.toLocaleString()}`;
                  },
                  // Additional tooltip callback to show the difference
                  afterBody: function(context) {
                    if (!shouldFillBetweenLines) {
                      return null;
                    }
                    // Assuming 'Income' is the first dataset and 'Expense' is the second
                    const incomeValue = context[0].parsed.y;
                    const expenseValue = context[1].parsed.y;
                    const difference = incomeValue - expenseValue;
                    return `Difference: ${difference.toLocaleString()}`;
                  }
                },
                filter: function(item, data) {
                  // Filter out 'Area Greater' and 'Area Lesser' from the tooltip items
                  const label = item.dataset.label;
                  return label !== 'Area Greater' && label !== 'Area Lesser';
                }
              },
              zoom: zoomOptions
            },
            interaction: {
              mode: 'index', // Show tooltips when hovering over any part of the x-axis
              intersect: false // Allow hovering anywhere along the line
            },
            elements: {
              point: {
                radius: 1
              }
            }
          }}
        />
      </div>
    </>
  );
};

export default LineChart;
