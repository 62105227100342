import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ArrowLeftRight, Box, EyeSlashFill, Pencil, PlusCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import { isSystemUser } from '../../../../../helpers/global';
import useAuth from '../../../../../hooks/useAuth';
import useLocalization from '../../../../../hooks/useLocalization';
import Tabs from '../../../../common/Tabs';
import UnderlineButton from '../../../../common/UnderlineButton';
import StrategyRearrangeModal from './StrategyRearrangeModal';

const StrategySelector = ({
  unkilledStrategies,
  readOnlyMode,
  onUpdateStrategyTimeClick,
  onAddNewStrategyClick,
  onSortStrategiesClick,
  showArchivedToClient,
  onCemetryClick,
  showStrategySortModal,
  setShowStrategySortModal,
  sortingStartegies,
  sortStrategies,
  updatingStrategy,
  selectedStrategy,
  activeCustomer,
  onEditStrategyClick,
  onDeleteStrategyClick,
  onCloneStrategyClick,
  onSelectStrategyClick,
  fromCustomerPortal,
  onKillStrategyClick,
  onSelectedStrategy
}) => {
  const { translate, isRTL } = useLocalization();
  const { user } = useAuth();

  const nonSandboxStrategies = useMemo(() => unkilledStrategies.filter(s => !s.isSandboxed), [unkilledStrategies]);

  const sandboxStrategies = useMemo(() => unkilledStrategies.filter(s => s.isSandboxed), [unkilledStrategies]);

  return (
    <div className="mb-3">
      <div className={`bg-light px-2 py-1 rounded border mb-1`}>
        <div className="d-flex align-items-center mx-1">
          <div className="flex-grow-1 d-flex align-items-center py-1">
            <h6 className="mb-0 text-muted mid fw-bold">
              {translate('strategies')} ({nonSandboxStrategies.length})
            </h6>
          </div>
          {!readOnlyMode && (
            <>
              <UnderlineButton
                iconSize={11}
                fontSize="mid"
                text={translate('update_strategy_time')}
                Icon={Pencil}
                onClick={onUpdateStrategyTimeClick}
                variant="dark"
              />

              {isSystemUser(user?.role) && (
                <UnderlineButton
                  iconSize={11}
                  fontSize="mid"
                  text={translate('new_strategy')}
                  Icon={PlusCircle}
                  onClick={onAddNewStrategyClick}
                  variant="success"
                />
              )}

              <div className="d-none d-lg-inline-block">
                {unkilledStrategies.length > 1 && (
                  <UnderlineButton
                    fontSize="mid"
                    iconSize={11}
                    text={translate('rearrange_strategies')}
                    Icon={ArrowLeftRight}
                    onClick={onSortStrategiesClick}
                  />
                )}

                {showArchivedToClient && (
                  <UnderlineButton
                    iconSize={11}
                    fontSize="mid"
                    text={translate('strategy_archive')}
                    Icon={EyeSlashFill}
                    onClick={onCemetryClick}
                    variant="primary-new"
                  />
                )}
              </div>

              {(unkilledStrategies.length > 1 || showArchivedToClient) && (
                <div className="d-inline-block d-lg-none">
                  <Dropdown>
                    <Dropdown.Toggle className="no-caret" variant="transparent" size="sm" aria-label="More actions">
                      <ThreeDotsVertical size={10} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {unkilledStrategies.length > 1 && (
                        <Dropdown.Item onClick={onSortStrategiesClick}>
                          <ArrowLeftRight size={12} />
                          <span className="align-middle mx-2">{translate('rearrange_strategies')}</span>
                        </Dropdown.Item>
                      )}
                      {showArchivedToClient && (
                        <Dropdown.Item onClick={onCemetryClick}>
                          <EyeSlashFill size={12} />
                          <span className="align-middle mx-2">{translate('strategy_archive')}</span>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              {unkilledStrategies.length > 1 && (
                <StrategyRearrangeModal
                  strategies={unkilledStrategies}
                  show={showStrategySortModal}
                  onHide={() => setShowStrategySortModal(false)}
                  showProgress={sortingStartegies}
                  onSubmit={sortStrategies}
                />
              )}
            </>
          )}
        </div>
        <div className="mt-2">
          <Tabs
            scrollable
            disabled={updatingStrategy}
            activeTabVariant={'primary'}
            activeTab={selectedStrategy?._id}
            selectedTab={activeCustomer['strategies'].find(s => s.isSelected)?._id}
            tabs={nonSandboxStrategies}
            tabVariantColors={nonSandboxStrategies.map(s =>
              s.isDefaultStrategy || s.isCreatedByClient ? 'success' : 'dark'
            )}
            onEditClick={
              isSystemUser(user?.role)
                ? t => onEditStrategyClick(activeCustomer['strategies'].find(s => s._id === t))
                : undefined
            }
            onDeleteClick={
              isSystemUser(user?.role)
                ? t => onDeleteStrategyClick(activeCustomer['strategies'].find(s => s._id === t))
                : undefined
            }
            onCloneClick={t => onCloneStrategyClick(activeCustomer['strategies'].find(s => s._id === t))}
            onSelectClick={
              !fromCustomerPortal
                ? t => onSelectStrategyClick(activeCustomer['strategies'].find(s => s._id === t))
                : undefined
            }
            onKillClick={
              showArchivedToClient && (t => onKillStrategyClick(activeCustomer['strategies'].find(s => s._id === t)))
            }
            onTabClick={t => onSelectedStrategy(activeCustomer['strategies'].find(s => s._id === t))}
            readOnlyMode={readOnlyMode}
          />
        </div>
      </div>
      <div className={`bg-light px-2 py-1 rounded border mb-1`}>
        <div className="d-flex align-items-center mx-1">
          <div className="flex-grow-1 d-flex align-items-center py-1">
            <h6 className="mb-0 text-muted mid fw-bold d-flex align-items-center gap-2">
              <Box />
              {translate('sandboxes')} ({sandboxStrategies.length})
            </h6>
          </div>
          {!readOnlyMode && (
            <>
              <UnderlineButton
                iconSize={11}
                fontSize="mid"
                text={translate('new_sandbox')}
                Icon={PlusCircle}
                onClick={() => onAddNewStrategyClick(true)}
                variant="success"
              />
            </>
          )}
        </div>
        <div className="mt-2">
          {sandboxStrategies.length > 0 ? (
            <Tabs
              scrollable
              disabled={updatingStrategy}
              activeTabVariant={'primary'}
              activeTab={selectedStrategy?._id}
              selectedTab={activeCustomer['strategies'].find(s => s.isSelected)?._id}
              tabs={sandboxStrategies}
              tabVariantColors={sandboxStrategies.map(s =>
                s.isDefaultStrategy || s.isCreatedByClient ? 'success' : 'dark'
              )}
              onEditClick={t => onEditStrategyClick(activeCustomer['strategies'].find(s => s._id === t))}
              onDeleteClick={t => onDeleteStrategyClick(activeCustomer['strategies'].find(s => s._id === t))}
              onCloneClick={t => onCloneStrategyClick(activeCustomer['strategies'].find(s => s._id === t))}
              onSelectClick={t => onSelectStrategyClick(activeCustomer['strategies'].find(s => s._id === t))}
              onTabClick={t => onSelectedStrategy(activeCustomer['strategies'].find(s => s._id === t))}
              readOnlyMode={readOnlyMode}
            />
          ) : (
            <h6 className="fw-bold large mb-1 px-1">{translate('nothing_to_show')}</h6>
          )}
        </div>
      </div>
    </div>
  );
};

export default StrategySelector;
