import React from 'react';
import { CircleFill } from 'react-bootstrap-icons';
import useLocalization from '../../../../../../hooks/useLocalization';

function LegendItem({ color, label, selected, onLegendClick }) {
  return (
    <div className="d-flex align-items-center gap-1 hover mb-0" onClick={onLegendClick}>
      <CircleFill size={9} style={{ color }} />
      <span
        className={`fw-bold tiny ${selected ? 'text-dark' : 'text-muted'}`}
        style={{
          textDecoration: selected ? 'none' : 'line-through',
          textDecorationThickness: '2px'
        }}
      >
        {label}
      </span>
    </div>
  );
}

const Legend = ({ legends = [], className = '', onLegendClick, onSelectAllClick }) => {
  const { translate } = useLocalization();
  return (
    <div className={`d-flex gap-3 flex-wrap ${className}`}>
      <LegendItem
        selected={true}
        onLegendClick={() => onSelectAllClick()}
        color={'#D3D3D3'}
        label={translate('select_unselect_all')}
      />
      {legends.map((l, index) => (
        <LegendItem
          onLegendClick={() => onLegendClick(l)}
          selected={l.selected}
          color={l.backgroundColor}
          label={l.label}
        />
      ))}
    </div>
  );
};

export default Legend;
