import React from 'react';
import { X, PersonCircle } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useLocalization from '../../hooks/useLocalization';
import './styles.scss';

const CustomerSelector = ({ apartmentExtraInfo, onRemoveCustomer, setBuyerConfirmationMeta }) => {
  const { translate } = useLocalization();

  const handleSelectBuyer = id => {
    const isCurrentlyBuyer = apartmentExtraInfo?.buyer === id;
    setBuyerConfirmationMeta({
      customerId: id,
      isCurrentlyBuyer
    });
  };

  return (
    <>
      {apartmentExtraInfo.customers?.map(customer => {
        const isBuyer = apartmentExtraInfo?.buyer === customer?._id;

        return (
          <OverlayTrigger
            key={customer._id}
            placement="top"
            overlay={<Tooltip>{translate(isBuyer ? 'click_to_unmark_as_buyer' : 'click_to_mark_as_buyer')}</Tooltip>}
          >
            <div className={`rounded border  wrapper ${isBuyer ? 'border-danger' : 'bg-primary border-primary'}`}>
              <div
                className={`customer bg-primary d-flex gap-1 align-items-center text-white hover rounded p-1`}
                style={{ cursor: 'pointer', height: '20px' }}
                onClick={() => handleSelectBuyer(customer._id)}
              >
                <PersonCircle size={14} />
                <h6 className="smaller fw-bold mb-0">{customer?.name || customer?.email || 'No name'}</h6>

                {isBuyer && (
                  <>
                    <span className="related-customer-badge px-1 tiny rounded-2 me-2 text-gray">
                      <span className="me-1">•</span>
                      <span>{translate('buyer')}</span>
                    </span>
                  </>
                )}
                <span
                  className="d-flex align-items-center"
                  style={{ pointerEvents: 'auto' }}
                  onClick={e => {
                    e.stopPropagation();
                    onRemoveCustomer(customer);
                  }}
                >
                  <X size={18} className="text-white" />
                </span>
              </div>
            </div>
          </OverlayTrigger>
        );
      })}
    </>
  );
};

export default CustomerSelector;
