import React from 'react';
import { Card } from 'react-bootstrap';

const ChartContainer = ({ children, title, maxWidth = 400, renderAdditionalComponent, minHeight}) => {
  return (
    <Card className="mb-4">
      <Card.Header>
        <div className="d-flex">
          <div className="flex-grow-1">{title}</div>
          {renderAdditionalComponent && <div className='d-flex align-items-center'>{renderAdditionalComponent()}</div>}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="mx-auto" style={{ maxWidth, minHeight }}>
          {children}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartContainer;
